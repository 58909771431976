import React from 'react';
import './Hero.css';
import MainImg from '../Images/6.png'
import Navbar from './Navbar';
import Headroom from 'react-headroom';

const HeroBanner = () => {
    return (
        <div className='Hero1'>
            <img src={MainImg} alt='xxx' className='hero-image' />
            <Headroom>
            <div className='sticky-navbar'>
                <Navbar />
            </div>
            </Headroom>
        </div>
    );
}

export default HeroBanner;

import React, { useState } from 'react';
import './ImageGrid.css'; // Import your CSS file
import GPImg1 from '../Images/Web P GP Gallery/10.webp'
import GPImg2 from '../Images/Web P GP Gallery/5.webp'
import GPImg3 from '../Images/Web P GP Gallery/3.webp'
import GPImg4 from '../Images/Web P GP Gallery/4.webp'
import GPimg5 from '../Images/Web P GP Gallery/2.webp'
import GPimg6 from '../Images/Greenpark Gallery/GP Gallery (2).webp'
import GPimg7 from '../Images/Greenpark Gallery/GP Gallery (3).webp'
import GPimg8 from '../Images/Greenpark Gallery/GP Gallery.webp'
import GPimg9 from '../Images/Web P GP Gallery/9.webp'
import GPimg10 from '../Images/Web P GP Gallery/1.webp'

const ImageGrid = () => {
    const [selectedImage, setSelectedImage] = useState(null);

    const images = [GPimg10, GPImg2, GPImg3, GPImg4, GPimg5,
        GPimg6, GPimg7, GPimg8, GPimg9, GPImg1
    ];

    const handleImageClick = (image) => {
        setSelectedImage(image);
    }

    const handleCloseCarousel = () => {
        setSelectedImage(null);
    }

    const handleNextImage = () => {
        const currentIndex = images.indexOf(selectedImage);
        if (currentIndex < images.length - 1) {
            setSelectedImage(images[currentIndex + 1]);
        } else {
            setSelectedImage(images[0]);
        }
    }
    const handlePrevImage = () => {
        const currentIndex = images.indexOf(selectedImage);
        if (currentIndex > 0) {
            setSelectedImage(images[currentIndex - 1]);
        }
    }
    return (
        <div className="ui1">
            <div className="ui2">
                <div className="ui3">
                    {images.slice(0, 5).map((image, index) => (
                        <div key={index} className="image-container">
                            <img
                                src={image}
                                alt={`Image ${index + 1}`}
                                onClick={() => handleImageClick(image)}
                            />
                        </div>
                    ))}
                </div>
                <div className="ui3">
                    {images.slice(5).map((image, index) => (
                        <div key={index} className="image-container">
                            <img
                                src={image}
                                alt={`Image ${index + 6}`}
                                onClick={() => handleImageClick(image)}
                            />
                        </div>
                    ))}
                </div>
            </div>

            {selectedImage && (
                <div className='overl'>
                    <div className="carousel">
                        <button className="close-button" onClick={handleCloseCarousel}><i className='fas fa-times'></i></button>
                        <button onClick={handlePrevImage}><i class="fa-solid fa-chevron-left"></i></button>
                        <img src={selectedImage} alt="Selected" />
                        <button onClick={handleNextImage}><i class="fa-solid fa-chevron-right"></i></button>
                    </div>                </div>


            )}
        </div>
    );
}
export default ImageGrid;


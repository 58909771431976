import React from 'react'
import './Scroller.css'
import { useState } from 'react';
import './Popup1.css'

const Images = () => {

    const [isOverlayVisible, setOverlayVisible] = useState(false);

    const toggleOverlay = () => {
        setOverlayVisible(!isOverlayVisible);
        document.body.classList.toggle('body-no-scroll', setOverlayVisible);
    };
    const closeModal = () => {
        setOverlayVisible(false);
        document.body.classList.remove('body-no-scroll');
    };



    return (
        <>
            <section id='classes'></section>
            <div >
                <p className='head'>Corefit Plus <span style={{ fontFamily: "BlueLB", fontSize: '30px', color: '#D7BD92' }}>Schedule</span>
                </p>                    <hr className='ere' />

            </div>

            <div className='mn3'>
                <p>Our schedule is designed with your busy life in mind. With flexible class times and a diverse range of Pilates classes, you can find the perfect fit for your fitness journey. Discover the freedom to choose and create your ideal Pilates routine at Corefit Plus.

                </p>
            </div>

            <div id="schedule">
                <div className="container">
                    <div className="heading-row">
                        <div className="heading"><span className="heading-left"></span><span className="heading-right"></span>
                            <div className="heading-border-row">
                                <div className="heading-border">
                                </div>
                            </div>
                        </div>
                    </div>
                    <div className="description-row">
                        <div className="description"></div></div>
                    <div className="table-container">
                        <div className="schedule-table">
                            <div className="table-header">
                                <div className="head-block date-label">DAY AND TIME<div className="week-day"></div></div>
                                <div className="head-block">MONDAY<div className="week-day"></div></div>
                                <div className="head-block">TUESDAY<div className="week-day"></div></div>
                                <div className="head-block">WEDNESDAY<div className="week-day"></div></div>
                                <div className="head-block">THURSDAY<div className="week-day"></div></div>
                                <div className="head-block">FRIDAY<div className="week-day"></div></div>
                                <div className="head-block">SATURDAY<div className="week-day"></div></div>
                                <div className="head-block">SUNDAY<div className="week-day"></div></div></div>
                            <div className="table-content">
                                <div className="even-row table-content-row">
                                    <div className="square-block time-block">
                                        <div className="text-div">07:00 AM<br />to<br />08:00 AM</div>
                                        <div className="book-div">BOOK NOW</div>
                                    </div>
                                    <div className="table-block square-block odd-block">
                                        <div className="text-div">Signature Full Body Reformer</div>
                                        <div className="book-div"><p onClick={toggleOverlay}>View Details</p>
                                            {isOverlayVisible &&
                                                <div className="overlay">
                                                    <div className="overlay-content">
                                                        <h3> SIGNATURE FULL BODY REFORMER</h3>
                                                        <div className='nbn'>
                                                            <p> Strengthen & Lengthen</p>
                                                            <p >  Intensity:<span style={{ color: '#6A6665', fontWeight: '600' }}> OPEN TO ALL LEVELS</span></p></div>
                                                        <div className='bnb'>
                                                            Designed for all, this strengthen and lengthen workout session will help you to build stamina,
                                                            flexibility, coordination, and strong balance. This session are done using Reformer machine, stability chair,
                                                            TRX, magic rings and other Pilates props for you to experience variations in fun and challenging ways. Experience
                                                            the benefits of Pilates with a twist.</div><br />
                                                        <p className='vbv'> To make your booking, please call/sms to +91 9667744326, 8595268566</p>

                                                        <i class="fa fa-times fa*3x close-button " onClick={closeModal} aria-hidden="true"></i>

                                                    </div>
                                                </div>}
                                        </div></div>
                                    <div className="table-block square-block even-block">
                                        <div className="text-div">Cardio Interval Pilates</div>
                                        <div className="book-div"><p onClick={toggleOverlay}>View Details</p>
                                            {isOverlayVisible && (
                                                <div className="overlay">
                                                    <div className="overlay-content">
                                                        <h3> CARDIO INTERVAL PILATES</h3>
                                                        <div className='nbn'>
                                                            <p> Heart-Pumping Interval Training</p>
                                                            <p >  Intensity:<span style={{ color: '#6A6665', fontWeight: '600' }}>INTERMEDIATE  TO ADVANCE LEVEL</span></p></div>
                                                        <div className='bnb'>
                                                            A high-intensity, calorie-blasting cardio workout with a combination of Jumpboard Reformer based strength training is what makes up this energetic class. This high-impact, interval training workout session is dynamic and fast-paced. A solid Pilates foundation is required to join this class, minimum 5 to 6 prior Pilate sessions would be ideal.</div><br />
                                                        <p className='vbv'> To make your booking, please call/sms to +91 9667744326, 8595268566</p>

                                                        <i class="fa fa-times fa*3x close-button " onClick={closeModal} aria-hidden="true"></i>

                                                    </div>
                                                </div>
                                            )}
                                        </div>
                                    </div>
                                    <div className="table-block square-block odd-block">
                                        <div className="text-div">Chair Balance reformer</div>
                                        <div className="book-div"><p onClick={toggleOverlay}>View Details</p>
                                            {isOverlayVisible && (
                                                <div className="overlay">
                                                    <div className="overlay-content">
                                                        <h3> CHAIR BALANCE REFORMER
                                                        </h3>
                                                        <div className='nbn'>
                                                            <p> Focus On Core And Balance
                                                            </p>
                                                            <p >  Intensity:<span style={{ color: '#6A6665', fontWeight: '600' }}> OPEN TO ALL LEVELS</span></p></div>
                                                        <div className='bnb'>
                                                            Great core stability workout routines performed on Wunda chairs to achieve maximum core
                                                            strength, muscular endurance and balance. Added choreography with this challenging and
                                                            complex Pilates' apparatus promises a high intensity and heart pumping workout. This
                                                            workout requires a strong sense of mind-body connection and breathing technique to help
                                                            gain maximum results!
                                                        </div><br />
                                                        <p className='vbv'> To make your booking, please call/sms to +91 9667744326, 8595268566</p>

                                                        <i class="fa fa-times fa*3x close-button " onClick={closeModal} aria-hidden="true"></i>

                                                    </div>
                                                </div>
                                            )}
                                        </div></div>
                                    <div className="table-block square-block even-block">
                                        <div className="text-div">Signature Full Body Reformer</div>
                                        <div className="book-div"><p onClick={toggleOverlay}>View Details</p>
                                            {isOverlayVisible &&
                                                <div className="overlay">
                                                    <div className="overlay-content">
                                                        <h3> SIGNATURE FULL BODY REFORMER</h3>
                                                        <div className='nbn'>
                                                            <p> Strengthen & Lengthen</p>
                                                            <p >  Intensity:<span style={{ color: '#6A6665', fontWeight: '600' }}> OPEN TO ALL LEVELS</span></p></div>
                                                        <div className='bnb'>
                                                            Designed for all, this strengthen and lengthen workout session will help you to build stamina,
                                                            flexibility, coordination, and strong balance. This session are done using Reformer machine, stability chair,
                                                            TRX, magic rings and other Pilates props for you to experience variations in fun and challenging ways. Experience
                                                            the benefits of Pilates with a twist.</div><br />
                                                        <p className='vbv'> To make your booking, please call/sms to +91 9667744326, 8595268566</p>

                                                        <i class="fa fa-times fa*3x close-button " onClick={closeModal} aria-hidden="true"></i>

                                                    </div>
                                                </div>}
                                        </div></div>
                                    <div className="table-block square-block even-block">
                                        <div className="text-div">Bosu Balance And Reformer </div>
                                        <div className="book-div"><p onClick={toggleOverlay}>View Details</p>
                                            {isOverlayVisible &&
                                                <div className="overlay">
                                                    <div className="overlay-content">
                                                        <h3>Bosu Balance And Reformer</h3>
                                                        <div className='nbn'>
                                                            <p> PUSHING BOUNDRIES AND TESTING STABILITY </p>
                                                            <p >  Intensity:<span style={{ color: '#6A6665', fontWeight: '600' }}> INTERMEDIATE LEVEL </span></p></div>
                                                        <div className='bnb'>Strengthening the Stabilizer Muscles. Try this class if you want an exercise routine to be more challenging and fun. Bosu trainers are versatile enough to use for stretching, balance-training, rehabilitat­-ion and even strength-training and plyometric training. Bosu trainer in combination with Functional training, TRX training and Free weights force your core to work constantly throughout the entire motion. This is an excellent way to make easy exercises more challenging.

                                                        </div><br />
                                                        <p className='vbv'> To make your booking, please call/sms to +91 9667744326, 8595268566</p>

                                                        <i class="fa fa-times fa*3x close-button " onClick={closeModal} aria-hidden="true"></i>

                                                    </div>
                                                </div>}
                                        </div></div>
                                    <div className="table-block square-block even-block">
                                        <div className="text-div">Burnout</div>
                                        <div className="book-div"><p onClick={toggleOverlay}>View Details</p>
                                            {isOverlayVisible && (
                                                <div className="overlay">
                                                    <div className="overlay-content">
                                                        <h3> BURNOUT

                                                        </h3>
                                                        <div className='nbn'>
                                                            <p> Lets Prove Our Endurance

                                                            </p>
                                                            <p >  Intensity:<span style={{ color: '#6A6665', fontWeight: '600' }}> INTERMEDIATE LEVEL</span></p></div>
                                                        <div className='bnb'>
                                                            A high-intensity endurance class aimed to burn maximum body fat working on bigger muscle groups. Expect squats, planks, challenging arm work, and compound exercises throughout this routine to keep your heart racing. The best workout to get toned and sculpted with long & lean muscles.
                                                        </div><br />
                                                        <p className='vbv'> To make your booking, please call/sms to +91 9667744326, 8595268566</p>

                                                        <i class="fa fa-times fa*3x close-button " onClick={closeModal} aria-hidden="true"></i>

                                                    </div>
                                                </div>
                                            )}
                                        </div>

                                    </div>
                                    <div className="table-block square-block odd-block">
                                        <div className="text-div">Signature Full Body Reformer</div>
                                        <div className="book-div"><p onClick={toggleOverlay}>View Details</p>
                                            {isOverlayVisible &&
                                                <div className="overlay">
                                                    <div className="overlay-content">
                                                        <h3> SIGNATURE FULL BODY REFORMER</h3>
                                                        <div className='nbn'>
                                                            <p> Strengthen & Lengthen</p>
                                                            <p >  Intensity:<span style={{ color: '#6A6665', fontWeight: '600' }}> OPEN TO ALL LEVELS</span></p></div>
                                                        <div className='bnb'>
                                                            Designed for all, this strengthen and lengthen workout session will help you to build stamina,
                                                            flexibility, coordination, and strong balance. This session are done using Reformer machine, stability chair,
                                                            TRX, magic rings and other Pilates props for you to experience variations in fun and challenging ways. Experience
                                                            the benefits of Pilates with a twist.</div><br />
                                                        <p className='vbv'> To make your booking, please call/sms to +91 9667744326, 8595268566</p>

                                                        <i class="fa fa-times fa*3x close-button " onClick={closeModal} aria-hidden="true"></i>

                                                    </div>
                                                </div>}
                                        </div>
                                    </div>
                                </div>
                                <div className="odd-row table-content-row">
                                    <div className="square-block time-block">
                                        <div className="text-div">08:00 AM<br />to<br />09:00 AM</div>
                                        <div className="book-div">BOOK NOW</div></div>
                                    <div className="table-block square-block odd-block">
                                        <div className="text-div">Energetic Cardio Pilates</div>
                                        <div className="book-div"><p onClick={toggleOverlay}>View Details</p>
                                            {isOverlayVisible && (
                                                <div className="overlay">
                                                    <div className="overlay-content">
                                                        <h3>ENERGETIC CARDIO PILATES
                                                        </h3>
                                                        <div className='nbn'>
                                                            <p>Sweat & Tone</p>
                                                            <p >  Intensity:<span style={{ color: '#6A6665', fontWeight: '600' }}> INTERMEDIATE TO ADVANCED LEVEL</span></p></div>
                                                        <div className='bnb'>
                                                            A fun workout routine which requires quick transitions between the jumpboard and reformer exercises. This class incorporates multiple fitness modalities to take your practice to a new level. Be ready to sweat and pump up your heart rate in this super engaging full-body Pilates workout. This high-energy, low-impact class is the perfect solution for all your cardio needs and will also help to increase your bone density.
                                                        </div><br />
                                                        <p className='vbv'> To make your booking, please call/sms to +91 9667744326, 8595268566</p>
                                                        <i class="fa fa-times fa*3x close-button " onClick={closeModal} aria-hidden="true"></i>
                                                    </div>
                                                </div>
                                            )}
                                        </div>                                          </div>
                                    <div className="table-block square-block even-block">
                                        <div className="text-div">TRX Functional &amp; Reformer</div>
                                        <div className="book-div"><p onClick={toggleOverlay}>View Details</p>
                                            {isOverlayVisible && (
                                                <div className="overlay">
                                                    <div className="overlay-content">
                                                        <h3>TRX FUNCTIONAL & REFORMER
                                                        </h3>
                                                        <div className='nbn'>
                                                            <p>Build Strength & Muscle
                                                            </p>
                                                            <p >  Intensity:<span style={{ color: '#6A6665', fontWeight: '600' }}> INTERMEDIATE LEVEL</span></p></div>
                                                        <div className='bnb'>
                                                            A Reformer based Pilates workout is combined with the incredibly effective TRX® suspension training system. Efficient and precise routines are performed to enhance strength, coordination, mobility and balance. An excellent choice for sports lovers who want to challenge their limits and enjoy muscle building exercises in quick transitions.
                                                        </div><br />
                                                        <p className='vbv'> To make your booking, please call/sms to +91 9667744326, 8595268566</p>
                                                        <i class="fa fa-times fa*3x close-button " onClick={closeModal} aria-hidden="true"></i>
                                                    </div>
                                                </div>
                                            )}
                                        </div>
                                    </div>
                                    <div className="table-block square-block odd-block">
                                        <div className="text-div">Chair Balance Reformer</div>
                                        <div className="book-div"><p onClick={toggleOverlay}>View Details</p>
                                            {isOverlayVisible && (
                                                <div className="overlay">
                                                    <div className="overlay-content">
                                                        <h3> CHAIR BALANCE REFORMER
                                                        </h3>
                                                        <div className='nbn'>
                                                            <p> Focus On Core And Balance
                                                            </p>
                                                            <p >  Intensity:<span style={{ color: '#6A6665', fontWeight: '600' }}> OPEN TO ALL LEVELS</span></p></div>
                                                        <div className='bnb'>
                                                            Great core stability workout routines performed on Wunda chairs to achieve maximum core
                                                            strength, muscular endurance and balance. Added choreography with this challenging and
                                                            complex Pilates' apparatus promises a high intensity and heart pumping workout. This
                                                            workout requires a strong sense of mind-body connection and breathing technique to help
                                                            gain maximum results!
                                                        </div><br />
                                                        <p className='vbv'> To make your booking, please call/sms to +91 9667744326, 8595268566</p>

                                                        <i class="fa fa-times fa*3x close-button " onClick={closeModal} aria-hidden="true"></i>

                                                    </div>
                                                </div>
                                            )}
                                        </div></div>
                                    <div className="table-block square-block even-block">
                                        <div className="text-div">Bosu Balance And Reformer </div>
                                        <div className="book-div"><p onClick={toggleOverlay}>View Details</p>
                                            {isOverlayVisible &&
                                                <div className="overlay">
                                                    <div className="overlay-content">
                                                        <h3>Bosu Balance And Reformer</h3>
                                                        <div className='nbn'>
                                                            <p> PUSHING BOUNDRIES AND TESTING STABILITY </p>
                                                            <p >  Intensity:<span style={{ color: '#6A6665', fontWeight: '600' }}> INTERMEDIATE LEVEL </span></p></div>
                                                        <div className='bnb'>Strengthening the Stabilizer Muscles. Try this class if you want an exercise routine to be more challenging and fun. Bosu trainers are versatile enough to use for stretching, balance-training, rehabilitat­-ion and even strength-training and plyometric training. Bosu trainer in combination with Functional training, TRX training and Free weights force your core to work constantly throughout the entire motion. This is an excellent way to make easy exercises more challenging.

                                                        </div><br />
                                                        <p className='vbv'> To make your booking, please call/sms to +91 9667744326, 8595268566</p>

                                                        <i class="fa fa-times fa*3x close-button " onClick={closeModal} aria-hidden="true"></i>

                                                    </div>
                                                </div>}
                                        </div></div><div className="table-block square-block odd-block">
                                        <div className="text-div">Burnout</div>
                                        <div className="book-div"><p onClick={toggleOverlay}>View Details</p>
                                            {isOverlayVisible && (
                                                <div className="overlay">
                                                    <div className="overlay-content">
                                                        <h3> BURNOUT

                                                        </h3>
                                                        <div className='nbn'>
                                                            <p> Lets Prove Our Endurance

                                                            </p>
                                                            <p >  Intensity:<span style={{ color: '#6A6665', fontWeight: '600' }}> INTERMEDIATE LEVEL</span></p></div>
                                                        <div className='bnb'>
                                                            A high-intensity endurance class aimed to burn maximum body fat working on bigger muscle groups. Expect squats, planks, challenging arm work, and compound exercises throughout this routine to keep your heart racing. The best workout to get toned and sculpted with long & lean muscles.
                                                        </div><br />
                                                        <p className='vbv'> To make your booking, please call/sms to +91 9667744326, 8595268566</p>

                                                        <i class="fa fa-times fa*3x close-button " onClick={closeModal} aria-hidden="true"></i>

                                                    </div>
                                                </div>
                                            )}
                                        </div>                                        </div>
                                    <div className="table-block square-block even-block">
                                        <div className="text-div">Signature Full Body Reformer</div>
                                        <div className="book-div"><p onClick={toggleOverlay}>View Details</p>
                                            {isOverlayVisible &&
                                                <div className="overlay">
                                                    <div className="overlay-content">
                                                        <h3> SIGNATURE FULL BODY REFORMER</h3>
                                                        <div className='nbn'>
                                                            <p> Strengthen & Lengthen</p>
                                                            <p >  Intensity:<span style={{ color: '#6A6665', fontWeight: '600' }}> OPEN TO ALL LEVELS</span></p></div>
                                                        <div className='bnb'>
                                                            Designed for all, this strengthen and lengthen workout session will help you to build stamina,
                                                            flexibility, coordination, and strong balance. This session are done using Reformer machine, stability chair,
                                                            TRX, magic rings and other Pilates props for you to experience variations in fun and challenging ways. Experience
                                                            the benefits of Pilates with a twist.</div><br />
                                                        <p className='vbv'> To make your booking, please call/sms to +91 9667744326, 8595268566</p>

                                                        <i class="fa fa-times fa*3x close-button " onClick={closeModal} aria-hidden="true"></i>

                                                    </div>
                                                </div>}
                                        </div></div>
                                    <div className="table-block square-block odd-block">
                                        <div className="text-div">Chair Balance Reformer</div>
                                        <div className="book-div"><p onClick={toggleOverlay}>View Details</p>
                                            {isOverlayVisible && (
                                                <div className="overlay">
                                                    <div className="overlay-content">
                                                        <h3> CHAIR BALANCE REFORMER
                                                        </h3>
                                                        <div className='nbn'>
                                                            <p> Focus On Core And Balance
                                                            </p>
                                                            <p >  Intensity:<span style={{ color: '#6A6665', fontWeight: '600' }}> OPEN TO ALL LEVELS</span></p></div>
                                                        <div className='bnb'>
                                                            Great core stability workout routines performed on Wunda chairs to achieve maximum core
                                                            strength, muscular endurance and balance. Added choreography with this challenging and
                                                            complex Pilates' apparatus promises a high intensity and heart pumping workout. This
                                                            workout requires a strong sense of mind-body connection and breathing technique to help
                                                            gain maximum results!
                                                        </div><br />
                                                        <p className='vbv'> To make your booking, please call/sms to +91 9667744326, 8595268566</p>

                                                        <i class="fa fa-times fa*3x close-button " onClick={closeModal} aria-hidden="true"></i>

                                                    </div>
                                                </div>
                                            )}
                                        </div>
                                    </div>
                                </div>
                                <div className="even-row table-content-row">
                                    <div className="square-block time-block">
                                        <div className="text-div">09:00 AM<br />to<br />10:00 AM</div>
                                        <div className="book-div">BOOK NOW</div></div>
                                    <div className="table-block square-block odd-block">
                                        <div className="text-div">Burnout</div>
                                        <div className="book-div"><p onClick={toggleOverlay}>View Details</p>
                                            {isOverlayVisible && (
                                                <div className="overlay">
                                                    <div className="overlay-content">
                                                        <h3> BURNOUT

                                                        </h3>
                                                        <div className='nbn'>
                                                            <p> Lets Prove Our Endurance

                                                            </p>
                                                            <p >  Intensity:<span style={{ color: '#6A6665', fontWeight: '600' }}> INTERMEDIATE LEVEL</span></p></div>
                                                        <div className='bnb'>
                                                            A high-intensity endurance class aimed to burn maximum body fat working on bigger muscle groups. Expect squats, planks, challenging arm work, and compound exercises throughout this routine to keep your heart racing. The best workout to get toned and sculpted with long & lean muscles.
                                                        </div><br />
                                                        <p className='vbv'> To make your booking, please call/sms to +91 9667744326, 8595268566</p>

                                                        <i class="fa fa-times fa*3x close-button " onClick={closeModal} aria-hidden="true"></i>

                                                    </div>
                                                </div>
                                            )}
                                        </div>                                        </div>
                                    <div className="table-block square-block even-block">
                                        <div className="text-div">Cardio Interval Pilates</div>
                                        <div className="book-div"><p onClick={toggleOverlay}>View Details</p>
                                            {isOverlayVisible && (
                                                <div className="overlay">
                                                    <div className="overlay-content">
                                                        <h3> CARDIO INTERVAL PILATES</h3>
                                                        <div className='nbn'>
                                                            <p> Heart-Pumping Interval Training</p>
                                                            <p >  Intensity:<span style={{ color: '#6A6665', fontWeight: '600' }}> INTERMEDIATE  TO ADVANCE LEVEL</span></p></div>
                                                        <div className='bnb'>
                                                            A high-intensity, calorie-blasting cardio workout with a combination of Jumpboard Reformer based strength training is what makes up this energetic class. This high-impact, interval training workout session is dynamic and fast-paced. A solid Pilates foundation is required to join this class, minimum 5 to 6 prior Pilate sessions would be ideal.</div><br />
                                                        <p className='vbv'> To make your booking, please call/sms to +91 9667744326, 8595268566</p>

                                                        <i class="fa fa-times fa*3x close-button " onClick={closeModal} aria-hidden="true"></i>

                                                    </div>
                                                </div>
                                            )}
                                        </div>
                                    </div>
                                    <div className="table-block square-block even-block">
                                        <div className="text-div">Bosu Balance And Reformer </div>
                                        <div className="book-div"><p onClick={toggleOverlay}>View Details</p>
                                            {isOverlayVisible &&
                                                <div className="overlay">
                                                    <div className="overlay-content">
                                                        <h3>Bosu Balance And Reformer</h3>
                                                        <div className='nbn'>
                                                            <p> PUSHING BOUNDRIES AND TESTING STABILITY </p>
                                                            <p >  Intensity:<span style={{ color: '#6A6665', fontWeight: '600' }}> INTERMEDIATE LEVEL </span></p></div>
                                                        <div className='bnb'>Strengthening the Stabilizer Muscles. Try this class if you want an exercise routine to be more challenging and fun. Bosu trainers are versatile enough to use for stretching, balance-training, rehabilitat­-ion and even strength-training and plyometric training. Bosu trainer in combination with Functional training, TRX training and Free weights force your core to work constantly throughout the entire motion. This is an excellent way to make easy exercises more challenging.

                                                        </div><br />
                                                        <p className='vbv'> To make your booking, please call/sms to +91 9667744326, 8595268566</p>

                                                        <i class="fa fa-times fa*3x close-button " onClick={closeModal} aria-hidden="true"></i>

                                                    </div>
                                                </div>}
                                        </div></div> <div className="table-block square-block even-block">
                                        <div className="text-div">Energetic Cardio Pilates</div>
                                        <div className="book-div"><p onClick={toggleOverlay}>View Details</p>
                                            {isOverlayVisible && (
                                                <div className="overlay">
                                                    <div className="overlay-content">
                                                        <h3>ENERGETIC CARDIO PILATES
                                                        </h3>
                                                        <div className='nbn'>
                                                            <p>Sweat & Tone</p>
                                                            <p >  Intensity:<span style={{ color: '#6A6665', fontWeight: '600' }}> INTERMEDIATE TO ADVANCED LEVEL</span></p></div>
                                                        <div className='bnb'>
                                                            A fun workout routine which requires quick transitions between the jumpboard and reformer exercises. This class incorporates multiple fitness modalities to take your practice to a new level. Be ready to sweat and pump up your heart rate in this super engaging full-body Pilates workout. This high-energy, low-impact class is the perfect solution for all your cardio needs and will also help to increase your bone density.
                                                        </div><br />
                                                        <p className='vbv'> To make your booking, please call/sms to +91 9667744326, 8595268566</p>
                                                        <i class="fa fa-times fa*3x close-button " onClick={closeModal} aria-hidden="true"></i>
                                                    </div>
                                                </div>
                                            )}
                                        </div>                                          </div>
                                    <div className="table-block square-block odd-block">
                                        <div className="text-div">Signature Full Body Reformer</div>
                                        <div className="book-div"><p onClick={toggleOverlay}>View Details</p>
                                            {isOverlayVisible &&
                                                <div className="overlay">
                                                    <div className="overlay-content">
                                                        <h3> SIGNATURE FULL BODY REFORMER</h3>
                                                        <div className='nbn'>
                                                            <p> Strengthen & Lengthen</p>
                                                            <p >  Intensity:<span style={{ color: '#6A6665', fontWeight: '600' }}> OPEN TO ALL LEVELS</span></p></div>
                                                        <div className='bnb'>
                                                            Designed for all, this strengthen and lengthen workout session will help you to build stamina,
                                                            flexibility, coordination, and strong balance. This session are done using Reformer machine, stability chair,
                                                            TRX, magic rings and other Pilates props for you to experience variations in fun and challenging ways. Experience
                                                            the benefits of Pilates with a twist.</div><br />
                                                        <p className='vbv'> To make your booking, please call/sms to +91 9667744326, 8595268566</p>

                                                        <i class="fa fa-times fa*3x close-button " onClick={closeModal} aria-hidden="true"></i>

                                                    </div>
                                                </div>}
                                        </div></div>
                                    <div className="table-block square-block even-block">
                                        <div className="text-div">Burnout</div>
                                        <div className="book-div"><p onClick={toggleOverlay}>View Details</p>
                                            {isOverlayVisible && (
                                                <div className="overlay">
                                                    <div className="overlay-content">
                                                        <h3> BURNOUT

                                                        </h3>
                                                        <div className='nbn'>
                                                            <p> Lets Prove Our Endurance

                                                            </p>
                                                            <p >  Intensity:<span style={{ color: '#6A6665', fontWeight: '600' }}> INTERMEDIATE LEVEL</span></p></div>
                                                        <div className='bnb'>
                                                            A high-intensity endurance class aimed to burn maximum body fat working on bigger muscle groups. Expect squats, planks, challenging arm work, and compound exercises throughout this routine to keep your heart racing. The best workout to get toned and sculpted with long & lean muscles.
                                                        </div><br />
                                                        <p className='vbv'> To make your booking, please call/sms to +91 9667744326, 8595268566</p>

                                                        <i class="fa fa-times fa*3x close-button " onClick={closeModal} aria-hidden="true"></i>

                                                    </div>
                                                </div>
                                            )}
                                        </div>
                                    </div>
                                    <div className="table-block square-block even-block">
                                        <div className="text-div">Upper Body Blast </div>
                                        <div className="book-div"><p onClick={toggleOverlay}>View Details</p>
                                            {isOverlayVisible &&
                                                <div className="overlay">
                                                    <div className="overlay-content">
                                                        <h3>Upper Body Blast</h3>
                                                        <div className='nbn'>
                                                            <p> SLEEK AND SCULPTED  </p>
                                                            <p >  Intensity:<span style={{ color: '#6A6665', fontWeight: '600' }}>OPEN TO ALL LEVELS </span></p></div>
                                                        <div className='bnb'>
                                                            This reformer workout targets the entire upper body with a mix of fun and challenging exercises. This class focuses on building strength in the entire upper body including the arms, shoulders, chest, triceps, and more.Different props are used in various ways to encourage proper form,engagement, and add a great spin to any movement.
                                                            best way!

                                                        </div><br />
                                                        <p className='vbv'> To make your booking, please call/sms to +91 9667744326, 8595268566</p>

                                                        <i class="fa fa-times fa*3x close-button " onClick={closeModal} aria-hidden="true"></i>

                                                    </div>
                                                </div>}
                                        </div></div>
                                </div>
                                <div className="odd-row table-content-row">
                                    <div className="square-block time-block">
                                        <div className="text-div">10:00 AM<br />to<br />11:00 AM</div>
                                        <div className="book-div">BOOK NOW</div></div>
                                    <div className="table-block square-block odd-block">
                                        <div className="text-div">Energetic Cardio Pilates</div>
                                        <div className="book-div"><p onClick={toggleOverlay}>View Details</p>
                                            {isOverlayVisible && (
                                                <div className="overlay">
                                                    <div className="overlay-content">
                                                        <h3>ENERGETIC CARDIO PILATES
                                                        </h3>
                                                        <div className='nbn'>
                                                            <p>Sweat & Tone</p>
                                                            <p >  Intensity:<span style={{ color: '#6A6665', fontWeight: '600' }}> INTERMEDIATE TO ADVANCED LEVEL</span></p></div>
                                                        <div className='bnb'>
                                                            A fun workout routine which requires quick transitions between the jumpboard and reformer exercises. This class incorporates multiple fitness modalities to take your practice to a new level. Be ready to sweat and pump up your heart rate in this super engaging full-body Pilates workout. This high-energy, low-impact class is the perfect solution for all your cardio needs and will also help to increase your bone density.
                                                        </div><br />
                                                        <p className='vbv'> To make your booking, please call/sms to +91 9667744326, 8595268566</p>
                                                        <i class="fa fa-times fa*3x close-button " onClick={closeModal} aria-hidden="true"></i>
                                                    </div>
                                                </div>
                                            )}
                                        </div>                                          </div>
                                    <div className="table-block square-block even-block">
                                        <div className="text-div">TRX Functional &amp; Reformer</div>
                                        <div className="book-div"><p onClick={toggleOverlay}>View Details</p>
                                            {isOverlayVisible && (
                                                <div className="overlay">
                                                    <div className="overlay-content">
                                                        <h3>TRX FUNCTIONAL & REFORMER
                                                        </h3>
                                                        <div className='nbn'>
                                                            <p>Build Strength & Muscle
                                                            </p>
                                                            <p >  Intensity:<span style={{ color: '#6A6665', fontWeight: '600' }}> INTERMEDIATE LEVEL</span></p></div>
                                                        <div className='bnb'>
                                                            A Reformer based Pilates workout is combined with the incredibly effective TRX® suspension training system. Efficient and precise routines are performed to enhance strength, coordination, mobility and balance. An excellent choice for sports lovers who want to challenge their limits and enjoy muscle building exercises in quick transitions.
                                                        </div><br />
                                                        <p className='vbv'> To make your booking, please call/sms to +91 9667744326, 8595268566</p>
                                                        <i class="fa fa-times fa*3x close-button " onClick={closeModal} aria-hidden="true"></i>
                                                    </div>
                                                </div>
                                            )}
                                        </div>


                                    </div>
                                    <div className="table-block square-block odd-block">
                                        <div className="text-div">Signature Full Body Reformer</div>
                                        <div className="book-div"><p onClick={toggleOverlay}>View Details</p>
                                            {isOverlayVisible &&
                                                <div className="overlay">
                                                    <div className="overlay-content">
                                                        <h3> SIGNATURE FULL BODY REFORMER</h3>
                                                        <div className='nbn'>
                                                            <p> Strengthen & Lengthen</p>
                                                            <p >  Intensity:<span style={{ color: '#6A6665', fontWeight: '600' }}> OPEN TO ALL LEVELS</span></p></div>
                                                        <div className='bnb'>
                                                            Designed for all, this strengthen and lengthen workout session will help you to build stamina,
                                                            flexibility, coordination, and strong balance. This session are done using Reformer machine, stability chair,
                                                            TRX, magic rings and other Pilates props for you to experience variations in fun and challenging ways. Experience
                                                            the benefits of Pilates with a twist.</div><br />
                                                        <p className='vbv'> To make your booking, please call/sms to +91 9667744326, 8595268566</p>

                                                        <i class="fa fa-times fa*3x close-button " onClick={closeModal} aria-hidden="true"></i>

                                                    </div>
                                                </div>}
                                        </div></div>
                                    <div className="table-block square-block even-block">
                                        <div className="text-div">Chair Balance Reformer</div>
                                        <div className="book-div"><p onClick={toggleOverlay}>View Details</p>
                                            {isOverlayVisible && (
                                                <div className="overlay">
                                                    <div className="overlay-content">
                                                        <h3> CHAIR BALANCE REFORMER
                                                        </h3>
                                                        <div className='nbn'>
                                                            <p> Focus On Core And Balance
                                                            </p>
                                                            <p >  Intensity:<span style={{ color: '#6A6665', fontWeight: '600' }}> OPEN TO ALL LEVELS</span></p></div>
                                                        <div className='bnb'>
                                                            Great core stability workout routines performed on Wunda chairs to achieve maximum core
                                                            strength, muscular endurance and balance. Added choreography with this challenging and
                                                            complex Pilates' apparatus promises a high intensity and heart pumping workout. This
                                                            workout requires a strong sense of mind-body connection and breathing technique to help
                                                            gain maximum results!
                                                        </div><br />
                                                        <p className='vbv'> To make your booking, please call/sms to +91 9667744326, 8595268566</p>

                                                        <i class="fa fa-times fa*3x close-button " onClick={closeModal} aria-hidden="true"></i>

                                                    </div>
                                                </div>
                                            )}
                                        </div></div>
                                    <div className="table-block square-block even-block">
                                        <div className="text-div">Coretastic Crunch</div>
                                        <div className="book-div"><p onClick={toggleOverlay}>View Details</p>
                                        {isOverlayVisible &&
                                                <div className="overlay">
                                                    <div className="overlay-content">
                                                        <h3>Coretastic Crunch</h3>
                                                        <div className='nbn'>
                                                            <p> SHAKE THE ABS  </p>
                                                            <p >  Intensity:<span style={{ color: '#6A6665', fontWeight: '600' }}> INTERMEDIATE LEVEL </span></p></div>
                                                        <div className='bnb'>
                                                            This reformer workout targets the deep muscles of the abdomen back and pelvis. This class focuses on maintaining proper form and alignment using your breath to support movements, and engaging your deep core muscles, including your obliques and transverse abs. Building strength in the core, from top to bottom + front to back, will provide you with better posture, strong breathing pattern, and an increase in balance+ stability. Plus, everyone loves working
                                                            their abs. Right?

                                                        </div><br />
                                                        <p className='vbv'> To make your booking, please call/sms to +91 9667744326, 8595268566</p>

                                                        <i class="fa fa-times fa*3x close-button " onClick={closeModal} aria-hidden="true"></i>

                                                    </div>
                                                </div>}
                                        </div>
                                    </div>
                                    <div className="table-block square-block even-block">
                                        <div className="text-div">Cardio Interval Pilates</div>
                                        <div className="book-div"><p onClick={toggleOverlay}>View Details</p>
                                            {isOverlayVisible && (
                                                <div className="overlay">
                                                    <div className="overlay-content">
                                                        <h3> CARDIO INTERVAL PILATES</h3>
                                                        <div className='nbn'>
                                                            <p> Heart-Pumping Interval Training</p>
                                                            <p >  Intensity:<span style={{ color: '#6A6665', fontWeight: '600' }}> INTERMEDIATE  TO ADVANCE LEVEL</span></p></div>
                                                        <div className='bnb'>
                                                            A high-intensity, calorie-blasting cardio workout with a combination of Jumpboard Reformer based strength training is what makes up this energetic class. This high-impact, interval training workout session is dynamic and fast-paced. A solid Pilates foundation is required to join this class, minimum 5 to 6 prior Pilate sessions would be ideal.</div><br />
                                                        <p className='vbv'> To make your booking, please call/sms to +91 9667744326, 8595268566</p>

                                                        <i class="fa fa-times fa*3x close-button " onClick={closeModal} aria-hidden="true"></i>

                                                    </div>
                                                </div>
                                            )}
                                        </div>
                                    </div>
                                    <div className="table-block square-block odd-block">
                                        <div className="text-div">Signature Full Body Reformer</div>
                                        <div className="book-div"><p onClick={toggleOverlay}>View Details</p>
                                            {isOverlayVisible &&
                                                <div className="overlay">
                                                    <div className="overlay-content">
                                                        <h3> SIGNATURE FULL BODY REFORMER</h3>
                                                        <div className='nbn'>
                                                            <p> Strengthen & Lengthen</p>
                                                            <p >  Intensity:<span style={{ color: '#6A6665', fontWeight: '600' }}> OPEN TO ALL LEVELS</span></p></div>
                                                        <div className='bnb'>
                                                            Designed for all, this strengthen and lengthen workout session will help you to build stamina,
                                                            flexibility, coordination, and strong balance. This session are done using Reformer machine, stability chair,
                                                            TRX, magic rings and other Pilates props for you to experience variations in fun and challenging ways. Experience
                                                            the benefits of Pilates with a twist.</div><br />
                                                        <p className='vbv'> To make your booking, please call/sms to +91 9667744326, 8595268566</p>

                                                        <i class="fa fa-times fa*3x close-button " onClick={closeModal} aria-hidden="true"></i>

                                                    </div>
                                                </div>}
                                        </div></div></div>
                                <div className="even-row table-content-row">
                                    <div className="square-block time-block">
                                        <div className="text-div">11:00 AM<br />to<br />12:00 PM</div>
                                        <div className="book-div">BOOK NOW</div></div>
                                    <div className="table-block square-block odd-block">
                                        <div className="text-div">Signature Full Body Reformer</div>
                                        <div className="book-div"><p onClick={toggleOverlay}>View Details</p>
                                            {isOverlayVisible &&
                                                <div className="overlay">
                                                    <div className="overlay-content">
                                                        <h3> SIGNATURE FULL BODY REFORMER</h3>
                                                        <div className='nbn'>
                                                            <p> Strengthen & Lengthen</p>
                                                            <p >  Intensity:<span style={{ color: '#6A6665', fontWeight: '600' }}> OPEN TO ALL LEVELS</span></p></div>
                                                        <div className='bnb'>
                                                            Designed for all, this strengthen and lengthen workout session will help you to build stamina,
                                                            flexibility, coordination, and strong balance. This session are done using Reformer machine, stability chair,
                                                            TRX, magic rings and other Pilates props for you to experience variations in fun and challenging ways. Experience
                                                            the benefits of Pilates with a twist.</div><br />
                                                        <p className='vbv'> To make your booking, please call/sms to +91 9667744326, 8595268566</p>

                                                        <i class="fa fa-times fa*3x close-button " onClick={closeModal} aria-hidden="true"></i>

                                                    </div>
                                                </div>}
                                        </div></div>
                                    <div className="table-block square-block even-block">
                                        <div className="text-div">Energetic Cardio Pilates</div>
                                        <div className="book-div"><p onClick={toggleOverlay}>View Details</p>
                                            {isOverlayVisible && (
                                                <div className="overlay">
                                                    <div className="overlay-content">
                                                        <h3>ENERGETIC CARDIO PILATES
                                                        </h3>
                                                        <div className='nbn'>
                                                            <p>Sweat & Tone</p>
                                                            <p >  Intensity:<span style={{ color: '#6A6665', fontWeight: '600' }}> INTERMEDIATE TO ADVANCED LEVEL</span></p></div>
                                                        <div className='bnb'>
                                                            A fun workout routine which requires quick transitions between the jumpboard and reformer exercises. This class incorporates multiple fitness modalities to take your practice to a new level. Be ready to sweat and pump up your heart rate in this super engaging full-body Pilates workout. This high-energy, low-impact class is the perfect solution for all your cardio needs and will also help to increase your bone density.
                                                        </div><br />
                                                        <p className='vbv'> To make your booking, please call/sms to +91 9667744326, 8595268566</p>
                                                        <i class="fa fa-times fa*3x close-button " onClick={closeModal} aria-hidden="true"></i>
                                                    </div>
                                                </div>
                                            )}
                                        </div>                                          </div>
                                    <div className="table-block square-block odd-block">
                                        <div className="text-div">Chair Balance Reformer</div>
                                        <div className="book-div"><p onClick={toggleOverlay}>View Details</p>
                                            {isOverlayVisible && (
                                                <div className="overlay">
                                                    <div className="overlay-content">
                                                        <h3> CHAIR BALANCE REFORMER
                                                        </h3>
                                                        <div className='nbn'>
                                                            <p> Focus On Core And Balance
                                                            </p>
                                                            <p >  Intensity:<span style={{ color: '#6A6665', fontWeight: '600' }}> OPEN TO ALL LEVELS</span></p></div>
                                                        <div className='bnb'>
                                                            Great core stability workout routines performed on Wunda chairs to achieve maximum core
                                                            strength, muscular endurance and balance. Added choreography with this challenging and
                                                            complex Pilates' apparatus promises a high intensity and heart pumping workout. This
                                                            workout requires a strong sense of mind-body connection and breathing technique to help
                                                            gain maximum results!
                                                        </div><br />
                                                        <p className='vbv'> To make your booking, please call/sms to +91 9667744326, 8595268566</p>

                                                        <i class="fa fa-times fa*3x close-button " onClick={closeModal} aria-hidden="true"></i>

                                                    </div>
                                                </div>
                                            )}
                                        </div></div>
                                    <div className="table-block square-block even-block">
                                        <div className="text-div">Signature Full Body Reformer</div>
                                        <div className="book-div"><p onClick={toggleOverlay}>View Details</p>
                                            {isOverlayVisible &&
                                                <div className="overlay">
                                                    <div className="overlay-content">
                                                        <h3> SIGNATURE FULL BODY REFORMER</h3>
                                                        <div className='nbn'>
                                                            <p> Strengthen & Lengthen</p>
                                                            <p >  Intensity:<span style={{ color: '#6A6665', fontWeight: '600' }}> OPEN TO ALL LEVELS</span></p></div>
                                                        <div className='bnb'>
                                                            Designed for all, this strengthen and lengthen workout session will help you to build stamina,
                                                            flexibility, coordination, and strong balance. This session are done using Reformer machine, stability chair,
                                                            TRX, magic rings and other Pilates props for you to experience variations in fun and challenging ways. Experience
                                                            the benefits of Pilates with a twist.</div><br />
                                                        <p className='vbv'> To make your booking, please call/sms to +91 9667744326, 8595268566</p>

                                                        <i class="fa fa-times fa*3x close-button " onClick={closeModal} aria-hidden="true"></i>

                                                    </div>
                                                </div>}
                                        </div></div>
                                    <div className="table-block square-block odd-block">
                                        <div className="text-div">TRX Functional &amp; Reformer</div>
                                        <div className="book-div"><p onClick={toggleOverlay}>View Details</p>
                                            {isOverlayVisible && (
                                                <div className="overlay">
                                                    <div className="overlay-content">
                                                        <h3>TRX FUNCTIONAL & REFORMER
                                                        </h3>
                                                        <div className='nbn'>
                                                            <p>Build Strength & Muscle
                                                            </p>
                                                            <p >  Intensity:<span style={{ color: '#6A6665', fontWeight: '600' }}> INTERMEDIATE LEVEL</span></p></div>
                                                        <div className='bnb'>
                                                            A Reformer based Pilates workout is combined with the incredibly effective TRX® suspension training system. Efficient and precise routines are performed to enhance strength, coordination, mobility and balance. An excellent choice for sports lovers who want to challenge their limits and enjoy muscle building exercises in quick transitions.
                                                        </div><br />
                                                        <p className='vbv'> To make your booking, please call/sms to +91 9667744326, 8595268566</p>
                                                        <i class="fa fa-times fa*3x close-button " onClick={closeModal} aria-hidden="true"></i>
                                                    </div>
                                                </div>
                                            )}
                                        </div>

                                    </div>
                                    <div className="table-block square-block even-block">
                                        <div className="text-div">Burnout</div>
                                        <div className="book-div"><p onClick={toggleOverlay}>View Details</p>
                                            {isOverlayVisible && (
                                                <div className="overlay">
                                                    <div className="overlay-content">
                                                        <h3> BURNOUT

                                                        </h3>
                                                        <div className='nbn'>
                                                            <p> Lets Prove Our Endurance

                                                            </p>
                                                            <p >  Intensity:<span style={{ color: '#6A6665', fontWeight: '600' }}> INTERMEDIATE LEVEL</span></p></div>
                                                        <div className='bnb'>
                                                            A high-intensity endurance class aimed to burn maximum body fat working on bigger muscle groups. Expect squats, planks, challenging arm work, and compound exercises throughout this routine to keep your heart racing. The best workout to get toned and sculpted with long & lean muscles.
                                                        </div><br />
                                                        <p className='vbv'> To make your booking, please call/sms to +91 9667744326, 8595268566</p>

                                                        <i class="fa fa-times fa*3x close-button " onClick={closeModal} aria-hidden="true"></i>

                                                    </div>
                                                </div>
                                            )}
                                        </div>
                                    </div>
                                    <div className="table-block square-block odd-block">
                                        <div className="text-div">Chair Balance Reformer</div>
                                        <div className="book-div"><p onClick={toggleOverlay}>View Details</p>
                                            {isOverlayVisible && (
                                                <div className="overlay">
                                                    <div className="overlay-content">
                                                        <h3> CHAIR BALANCE REFORMER
                                                        </h3>
                                                        <div className='nbn'>
                                                            <p> Focus On Core And Balance
                                                            </p>
                                                            <p >  Intensity:<span style={{ color: '#6A6665', fontWeight: '600' }}> OPEN TO ALL LEVELS</span></p></div>
                                                        <div className='bnb'>
                                                            Great core stability workout routines performed on Wunda chairs to achieve maximum core
                                                            strength, muscular endurance and balance. Added choreography with this challenging and
                                                            complex Pilates' apparatus promises a high intensity and heart pumping workout. This
                                                            workout requires a strong sense of mind-body connection and breathing technique to help
                                                            gain maximum results!
                                                        </div><br />
                                                        <p className='vbv'> To make your booking, please call/sms to +91 9667744326, 8595268566</p>

                                                        <i class="fa fa-times fa*3x close-button " onClick={closeModal} aria-hidden="true"></i>

                                                    </div>
                                                </div>
                                            )}
                                        </div></div></div>
                                <div className="odd-row table-content-row">
                                    <div className="square-block time-block">
                                        <div className="text-div">12:00 PM<br />to<br />1:00 PM</div>
                                        <div className="book-div">BOOK NOW</div></div>
                                    <div className="table-block square-block even-block">
                                        <div className="text-div">Bosu Balance And Reformer </div>
                                        <div className="book-div"><p onClick={toggleOverlay}>View Details</p>
                                            {isOverlayVisible &&
                                                <div className="overlay">
                                                    <div className="overlay-content">
                                                        <h3>Bosu Balance And Reformer</h3>
                                                        <div className='nbn'>
                                                            <p> PUSHING BOUNDRIES AND TESTING STABILITY </p>
                                                            <p >  Intensity:<span style={{ color: '#6A6665', fontWeight: '600' }}> INTERMEDIATE LEVEL </span></p></div>
                                                        <div className='bnb'>Strengthening the Stabilizer Muscles. Try this class if you want an exercise routine to be more challenging and fun. Bosu trainers are versatile enough to use for stretching, balance-training, rehabilitat­-ion and even strength-training and plyometric training. Bosu trainer in combination with Functional training, TRX training and Free weights force your core to work constantly throughout the entire motion. This is an excellent way to make easy exercises more challenging.

                                                        </div><br />
                                                        <p className='vbv'> To make your booking, please call/sms to +91 9667744326, 8595268566</p>

                                                        <i class="fa fa-times fa*3x close-button " onClick={closeModal} aria-hidden="true"></i>

                                                    </div>
                                                </div>}
                                        </div></div>
                                    <div className="table-block square-block even-block">
                                        <div className="text-div">Chair Balance Reformer</div>
                                        <div className="book-div"><p onClick={toggleOverlay}>View Details</p>
                                            {isOverlayVisible && (
                                                <div className="overlay">
                                                    <div className="overlay-content">
                                                        <h3> CHAIR BALANCE REFORMER
                                                        </h3>
                                                        <div className='nbn'>
                                                            <p> Focus On Core And Balance
                                                            </p>
                                                            <p >  Intensity:<span style={{ color: '#6A6665', fontWeight: '600' }}> OPEN TO ALL LEVELS</span></p></div>
                                                        <div className='bnb'>
                                                            Great core stability workout routines performed on Wunda chairs to achieve maximum core
                                                            strength, muscular endurance and balance. Added choreography with this challenging and
                                                            complex Pilates' apparatus promises a high intensity and heart pumping workout. This
                                                            workout requires a strong sense of mind-body connection and breathing technique to help
                                                            gain maximum results!
                                                        </div><br />
                                                        <p className='vbv'> To make your booking, please call/sms to +91 9667744326, 8595268566</p>

                                                        <i class="fa fa-times fa*3x close-button " onClick={closeModal} aria-hidden="true"></i>

                                                    </div>
                                                </div>
                                            )}
                                        </div></div>
                                    <div className="table-block square-block odd-block">
                                        <div className="text-div">Signature Full Body Reformer</div>
                                        <div className="book-div"><p onClick={toggleOverlay}>View Details</p>
                                            {isOverlayVisible &&
                                                <div className="overlay">
                                                    <div className="overlay-content">
                                                        <h3> SIGNATURE FULL BODY REFORMER</h3>
                                                        <div className='nbn'>
                                                            <p> Strengthen & Lengthen</p>
                                                            <p >  Intensity:<span style={{ color: '#6A6665', fontWeight: '600' }}> OPEN TO ALL LEVELS</span></p></div>
                                                        <div className='bnb'>
                                                            Designed for all, this strengthen and lengthen workout session will help you to build stamina,
                                                            flexibility, coordination, and strong balance. This session are done using Reformer machine, stability chair,
                                                            TRX, magic rings and other Pilates props for you to experience variations in fun and challenging ways. Experience
                                                            the benefits of Pilates with a twist.</div><br />
                                                        <p className='vbv'> To make your booking, please call/sms to +91 9667744326, 8595268566</p>

                                                        <i class="fa fa-times fa*3x close-button " onClick={closeModal} aria-hidden="true"></i>

                                                    </div>
                                                </div>}
                                        </div></div>
                                    <div className="table-block square-block even-block">
                                        <div className="text-div">Bosu Balance And Reformer </div>
                                        <div className="book-div"><p onClick={toggleOverlay}>View Details</p>
                                            {isOverlayVisible &&
                                                <div className="overlay">
                                                    <div className="overlay-content">
                                                        <h3>Bosu Balance And Reformer</h3>
                                                        <div className='nbn'>
                                                            <p> PUSHING BOUNDRIES AND TESTING STABILITY </p>
                                                            <p >  Intensity:<span style={{ color: '#6A6665', fontWeight: '600' }}> INTERMEDIATE LEVEL </span></p></div>
                                                        <div className='bnb'>Strengthening the Stabilizer Muscles. Try this class if you want an exercise routine to be more challenging and fun. Bosu trainers are versatile enough to use for stretching, balance-training, rehabilitat­-ion and even strength-training and plyometric training. Bosu trainer in combination with Functional training, TRX training and Free weights force your core to work constantly throughout the entire motion. This is an excellent way to make easy exercises more challenging.

                                                        </div><br />
                                                        <p className='vbv'> To make your booking, please call/sms to +91 9667744326, 8595268566</p>

                                                        <i class="fa fa-times fa*3x close-button " onClick={closeModal} aria-hidden="true"></i>

                                                    </div>
                                                </div>}
                                        </div></div>
                                    <div className="table-block square-block odd-block">
                                        <div className="text-div">Signature Full Body Reformer</div>
                                        <div className="book-div"><p onClick={toggleOverlay}>View Details</p>
                                            {isOverlayVisible &&
                                                <div className="overlay">
                                                    <div className="overlay-content">
                                                        <h3> SIGNATURE FULL BODY REFORMER</h3>
                                                        <div className='nbn'>
                                                            <p> Strengthen & Lengthen</p>
                                                            <p >  Intensity:<span style={{ color: '#6A6665', fontWeight: '600' }}> OPEN TO ALL LEVELS</span></p></div>
                                                        <div className='bnb'>
                                                            Designed for all, this strengthen and lengthen workout session will help you to build stamina,
                                                            flexibility, coordination, and strong balance. This session are done using Reformer machine, stability chair,
                                                            TRX, magic rings and other Pilates props for you to experience variations in fun and challenging ways. Experience
                                                            the benefits of Pilates with a twist.</div><br />
                                                        <p className='vbv'> To make your booking, please call/sms to +91 9667744326, 8595268566</p>

                                                        <i class="fa fa-times fa*3x close-button " onClick={closeModal} aria-hidden="true"></i>

                                                    </div>
                                                </div>}
                                        </div></div>
                                    <div className="table-block square-block even-block">
                                        <div className="text-div">TRX Functional &amp; Reformer</div>
                                        <div className="book-div"><p onClick={toggleOverlay}>View Details</p>
                                            {isOverlayVisible && (
                                                <div className="overlay">
                                                    <div className="overlay-content">
                                                        <h3>TRX FUNCTIONAL & REFORMER
                                                        </h3>
                                                        <div className='nbn'>
                                                            <p>Build Strength & Muscle
                                                            </p>
                                                            <p >  Intensity:<span style={{ color: '#6A6665', fontWeight: '600' }}> INTERMEDIATE LEVEL</span></p></div>
                                                        <div className='bnb'>
                                                            A Reformer based Pilates workout is combined with the incredibly effective TRX® suspension training system. Efficient and precise routines are performed to enhance strength, coordination, mobility and balance. An excellent choice for sports lovers who want to challenge their limits and enjoy muscle building exercises in quick transitions.
                                                        </div><br />
                                                        <p className='vbv'> To make your booking, please call/sms to +91 9667744326, 8595268566</p>
                                                        <i class="fa fa-times fa*3x close-button " onClick={closeModal} aria-hidden="true"></i>
                                                    </div>
                                                </div>
                                            )}
                                        </div>
                                    </div>


                                    <div className="table-block square-block even-block">
                                        <div className="text-div">Upper Body Blast </div>
                                        <div className="book-div"><p onClick={toggleOverlay}>View Details</p>
                                            {isOverlayVisible &&
                                                <div className="overlay">
                                                    <div className="overlay-content">
                                                        <h3>Upper Body Blast</h3>
                                                        <div className='nbn'>
                                                            <p> SLEEK AND SCULPTED  </p>
                                                            <p >  Intensity:<span style={{ color: '#6A6665', fontWeight: '600' }}>OPEN TO ALL LEVELS </span></p></div>
                                                        <div className='bnb'>
                                                            This reformer workout targets the entire upper body with a mix of fun and challenging exercises. This class focuses on building strength in the entire upper body including the arms, shoulders, chest, triceps, and more.Different props are used in various ways to encourage proper form,engagement, and add a great spin to any movement.
                                                            best way!

                                                        </div><br />
                                                        <p className='vbv'> To make your booking, please call/sms to +91 9667744326, 8595268566</p>

                                                        <i class="fa fa-times fa*3x close-button " onClick={closeModal} aria-hidden="true"></i>

                                                    </div>
                                                </div>}
                                        </div></div>
                                </div>



                                <div className="even-row table-content-row">
                                    <div className="square-block time-block">
                                        <div className="text-div">04:00 PM<br />to<br />05:00 PM</div>
                                        <div className="book-div">BOOK NOW</div>
                                    </div>
                                    <div className="table-block square-block odd-block">
                                        <div className="text-div">Cardio Interval Pilates</div>
                                        <div className="book-div"><p onClick={toggleOverlay}>View Details</p>
                                            {isOverlayVisible && (
                                                <div className="overlay">
                                                    <div className="overlay-content">
                                                        <h3> CARDIO INTERVAL PILATES</h3>
                                                        <div className='nbn'>
                                                            <p> Heart-Pumping Interval Training</p>
                                                            <p >  Intensity:<span style={{ color: '#6A6665', fontWeight: '600' }}>INTERMEDIATE  TO ADVANCE LEVEL</span></p></div>
                                                        <div className='bnb'>
                                                            A high-intensity, calorie-blasting cardio workout with a combination of Jumpboard Reformer based strength training is what makes up this energetic class. This high-impact, interval training workout session is dynamic and fast-paced. A solid Pilates foundation is required to join this class, minimum 5 to 6 prior Pilate sessions would be ideal.</div><br />
                                                        <p className='vbv'> To make your booking, please call/sms to +91 9667744326, 8595268566</p>

                                                        <i class="fa fa-times fa*3x close-button " onClick={closeModal} aria-hidden="true"></i>

                                                    </div>
                                                </div>
                                            )}
                                        </div>
                                    </div>
                                    <div className="table-block square-block even-block">
                                        <div className="text-div">Upper Body Blast </div>
                                        <div className="book-div"><p onClick={toggleOverlay}>View Details</p>
                                            {isOverlayVisible &&
                                                <div className="overlay">
                                                    <div className="overlay-content">
                                                        <h3>Upper Body Blast</h3>
                                                        <div className='nbn'>
                                                            <p> SLEEK AND SCULPTED  </p>
                                                            <p >  Intensity:<span style={{ color: '#6A6665', fontWeight: '600' }}>OPEN TO ALL LEVELS </span></p></div>
                                                        <div className='bnb'>
                                                            This reformer workout targets the entire upper body with a mix of fun and challenging exercises. This class focuses on building strength in the entire upper body including the arms, shoulders, chest, triceps, and more.Different props are used in various ways to encourage proper form,engagement, and add a great spin to any movement.
                                                            best way!

                                                        </div><br />
                                                        <p className='vbv'> To make your booking, please call/sms to +91 9667744326, 8595268566</p>

                                                        <i class="fa fa-times fa*3x close-button " onClick={closeModal} aria-hidden="true"></i>

                                                    </div>
                                                </div>}
                                        </div></div>
                                    <div className="table-block square-block odd-block">
                                        <div className="text-div">Energetic Cardio Pilates</div>
                                        <div className="book-div"><p onClick={toggleOverlay}>View Details</p>
                                            {isOverlayVisible && (
                                                <div className="overlay">
                                                    <div className="overlay-content">
                                                        <h3>ENERGETIC CARDIO PILATES
                                                        </h3>
                                                        <div className='nbn'>
                                                            <p>Sweat & Tone</p>
                                                            <p >  Intensity:<span style={{ color: '#6A6665', fontWeight: '600' }}> INTERMEDIATE TO ADVANCED LEVEL</span></p></div>
                                                        <div className='bnb'>
                                                            A fun workout routine which requires quick transitions between the jumpboard and reformer exercises. This class incorporates multiple fitness modalities to take your practice to a new level. Be ready to sweat and pump up your heart rate in this super engaging full-body Pilates workout. This high-energy, low-impact class is the perfect solution for all your cardio needs and will also help to increase your bone density.
                                                        </div><br />
                                                        <p className='vbv'> To make your booking, please call/sms to +91 9667744326, 8595268566</p>
                                                        <i class="fa fa-times fa*3x close-button " onClick={closeModal} aria-hidden="true"></i>
                                                    </div>
                                                </div>
                                            )}
                                        </div>                                      </div>
                                    <div className="table-block square-block even-block">
                                        <div className="text-div">Burnout</div>
                                        <div className="book-div"><p onClick={toggleOverlay}>View Details</p>
                                            {isOverlayVisible && (
                                                <div className="overlay">
                                                    <div className="overlay-content">
                                                        <h3> BURNOUT

                                                        </h3>
                                                        <div className='nbn'>
                                                            <p> Lets Prove Our Endurance

                                                            </p>
                                                            <p >  Intensity:<span style={{ color: '#6A6665', fontWeight: '600' }}> INTERMEDIATE LEVEL</span></p></div>
                                                        <div className='bnb'>
                                                            A high-intensity endurance class aimed to burn maximum body fat working on bigger muscle groups. Expect squats, planks, challenging arm work, and compound exercises throughout this routine to keep your heart racing. The best workout to get toned and sculpted with long & lean muscles.
                                                        </div><br />
                                                        <p className='vbv'> To make your booking, please call/sms to +91 9667744326, 8595268566</p>

                                                        <i class="fa fa-times fa*3x close-button " onClick={closeModal} aria-hidden="true"></i>

                                                    </div>
                                                </div>
                                            )}
                                        </div>                                    </div>
                                    <div className="table-block square-block odd-block">
                                        <div className="text-div">Chair Balance Reformer</div>
                                        <div className="book-div"><p onClick={toggleOverlay}>View Details</p>
                                            {isOverlayVisible && (
                                                <div className="overlay">
                                                    <div className="overlay-content">
                                                        <h3> CHAIR BALANCE REFORMER
                                                        </h3>
                                                        <div className='nbn'>
                                                            <p> Focus On Core And Balance
                                                            </p>
                                                            <p >  Intensity:<span style={{ color: '#6A6665', fontWeight: '600' }}> OPEN TO ALL LEVELS</span></p></div>
                                                        <div className='bnb'>
                                                            Great core stability workout routines performed on Wunda chairs to achieve maximum core
                                                            strength, muscular endurance and balance. Added choreography with this challenging and
                                                            complex Pilates' apparatus promises a high intensity and heart pumping workout. This
                                                            workout requires a strong sense of mind-body connection and breathing technique to help
                                                            gain maximum results!
                                                        </div><br />
                                                        <p className='vbv'> To make your booking, please call/sms to +91 9667744326, 8595268566</p>

                                                        <i class="fa fa-times fa*3x close-button " onClick={closeModal} aria-hidden="true"></i>

                                                    </div>
                                                </div>
                                            )}
                                        </div>
                                    </div>
                                    <div className="table-block square-block even-block">
                                        <div className="text-div">Cardio Interval Pilates</div>
                                        <div className="book-div"><p onClick={toggleOverlay}>View Details</p>
                                            {isOverlayVisible && (
                                                <div className="overlay">
                                                    <div className="overlay-content">
                                                        <h3> CARDIO INTERVAL PILATES</h3>
                                                        <div className='nbn'>
                                                            <p> Heart-Pumping Interval Training</p>
                                                            <p >  Intensity:<span style={{ color: '#6A6665', fontWeight: '600' }}>INTERMEDIATE  TO ADVANCE LEVEL</span></p></div>
                                                        <div className='bnb'>
                                                            A high-intensity, calorie-blasting cardio workout with a combination of Jumpboard Reformer based strength training is what makes up this energetic class. This high-impact, interval training workout session is dynamic and fast-paced. A solid Pilates foundation is required to join this class, minimum 5 to 6 prior Pilate sessions would be ideal.</div><br />
                                                        <p className='vbv'> To make your booking, please call/sms to +91 9667744326, 8595268566</p>

                                                        <i class="fa fa-times fa*3x close-button " onClick={closeModal} aria-hidden="true"></i>

                                                    </div>
                                                </div>
                                            )}
                                        </div>

                                    </div>
                                </div>
                                <div className="odd-row table-content-row">
                                    <div className="square-block time-block">
                                        <div className="text-div">05:00 PM<br />to<br />06:00 PM</div>
                                        <div className="book-div">BOOK NOW</div>
                                    </div>
                                    <div className="table-block square-block odd-block">
                                        <div className="text-div">Signature Full Body Reformer</div>
                                        <div className="book-div"><p onClick={toggleOverlay}>View Details</p>
                                            {isOverlayVisible &&
                                                <div className="overlay">
                                                    <div className="overlay-content">
                                                        <h3> SIGNATURE FULL BODY REFORMER</h3>
                                                        <div className='nbn'>
                                                            <p> Strengthen & Lengthen</p>
                                                            <p >  Intensity:<span style={{ color: '#6A6665', fontWeight: '600' }}> OPEN TO ALL LEVELS</span></p></div>
                                                        <div className='bnb'>
                                                            Designed for all, this strengthen and lengthen workout session will help you to build stamina,
                                                            flexibility, coordination, and strong balance. This session are done using Reformer machine, stability chair,
                                                            TRX, magic rings and other Pilates props for you to experience variations in fun and challenging ways. Experience
                                                            the benefits of Pilates with a twist.</div><br />
                                                        <p className='vbv'> To make your booking, please call/sms to +91 9667744326, 8595268566</p>

                                                        <i class="fa fa-times fa*3x close-button " onClick={closeModal} aria-hidden="true"></i>

                                                    </div>
                                                </div>}
                                        </div>                                    </div>
                                    <div className="table-block square-block even-block">
                                        <div className="text-div">Chair Balance Reformer</div>
                                        <div className="book-div"><p onClick={toggleOverlay}>View Details</p>
                                            {isOverlayVisible && (
                                                <div className="overlay">
                                                    <div className="overlay-content">
                                                        <h3> CHAIR BALANCE REFORMER
                                                        </h3>
                                                        <div className='nbn'>
                                                            <p> Focus On Core And Balance
                                                            </p>
                                                            <p >  Intensity:<span style={{ color: '#6A6665', fontWeight: '600' }}> OPEN TO ALL LEVELS</span></p></div>
                                                        <div className='bnb'>
                                                            Great core stability workout routines performed on Wunda chairs to achieve maximum core
                                                            strength, muscular endurance and balance. Added choreography with this challenging and
                                                            complex Pilates' apparatus promises a high intensity and heart pumping workout. This
                                                            workout requires a strong sense of mind-body connection and breathing technique to help
                                                            gain maximum results!
                                                        </div><br />
                                                        <p className='vbv'> To make your booking, please call/sms to +91 9667744326, 8595268566</p>

                                                        <i class="fa fa-times fa*3x close-button " onClick={closeModal} aria-hidden="true"></i>

                                                    </div>
                                                </div>
                                            )}
                                        </div>
                                    </div>
                                    <div className="table-block square-block odd-block">
                                        <div className="text-div">Burnout</div>
                                        <div className="book-div"><p onClick={toggleOverlay}>View Details</p>
                                            {isOverlayVisible && (
                                                <div className="overlay">
                                                    <div className="overlay-content">
                                                        <h3> BURNOUT

                                                        </h3>
                                                        <div className='nbn'>
                                                            <p> Lets Prove Our Endurance

                                                            </p>
                                                            <p >  Intensity:<span style={{ color: '#6A6665', fontWeight: '600' }}> INTERMEDIATE LEVEL</span></p></div>
                                                        <div className='bnb'>
                                                            A high-intensity endurance class aimed to burn maximum body fat working on bigger muscle groups. Expect squats, planks, challenging arm work, and compound exercises throughout this routine to keep your heart racing. The best workout to get toned and sculpted with long & lean muscles.
                                                        </div><br />
                                                        <p className='vbv'> To make your booking, please call/sms to +91 9667744326, 8595268566</p>

                                                        <i class="fa fa-times fa*3x close-button " onClick={closeModal} aria-hidden="true"></i>

                                                    </div>
                                                </div>
                                            )}
                                        </div>                                    </div>
                                    <div className="table-block square-block even-block">
                                        <div className="text-div">TRX Functional &amp; Reformer</div>
                                        <div className="book-div"><p onClick={toggleOverlay}>View Details</p>
                                            {isOverlayVisible && (
                                                <div className="overlay">
                                                    <div className="overlay-content">
                                                        <h3>TRX FUNCTIONAL & REFORMER
                                                        </h3>
                                                        <div className='nbn'>
                                                            <p>Build Strength & Muscle
                                                            </p>
                                                            <p >  Intensity:<span style={{ color: '#6A6665', fontWeight: '600' }}> INTERMEDIATE LEVEL</span></p></div>
                                                        <div className='bnb'>
                                                            A Reformer based Pilates workout is combined with the incredibly effective TRX® suspension training system. Efficient and precise routines are performed to enhance strength, coordination, mobility and balance. An excellent choice for sports lovers who want to challenge their limits and enjoy muscle building exercises in quick transitions.
                                                        </div><br />
                                                        <p className='vbv'> To make your booking, please call/sms to +91 9667744326, 8595268566</p>
                                                        <i class="fa fa-times fa*3x close-button " onClick={closeModal} aria-hidden="true"></i>
                                                    </div>
                                                </div>
                                            )}
                                        </div>
                                    </div>
                                    <div className="table-block square-block odd-block">
                                        <div className="text-div">Energetic Cardio Pilates</div>
                                        <div className="book-div"><p onClick={toggleOverlay}>View Details</p>
                                            {isOverlayVisible && (
                                                <div className="overlay">
                                                    <div className="overlay-content">
                                                        <h3>ENERGETIC CARDIO PILATES
                                                        </h3>
                                                        <div className='nbn'>
                                                            <p>Sweat & Tone</p>
                                                            <p >  Intensity:<span style={{ color: '#6A6665', fontWeight: '600' }}> INTERMEDIATE TO ADVANCED LEVEL</span></p></div>
                                                        <div className='bnb'>
                                                            A fun workout routine which requires quick transitions between the jumpboard and reformer exercises. This class incorporates multiple fitness modalities to take your practice to a new level. Be ready to sweat and pump up your heart rate in this super engaging full-body Pilates workout. This high-energy, low-impact class is the perfect solution for all your cardio needs and will also help to increase your bone density.
                                                        </div><br />
                                                        <p className='vbv'> To make your booking, please call/sms to +91 9667744326, 8595268566</p>
                                                        <i class="fa fa-times fa*3x close-button " onClick={closeModal} aria-hidden="true"></i>
                                                    </div>
                                                </div>
                                            )}
                                        </div>
                                    </div>
                                    <div className="table-block square-block even-block">
                                        <div className="text-div">Coretastic Crunch</div>
                                        <div className="book-div"><p onClick={toggleOverlay}>View Details</p>
                                            {isOverlayVisible &&
                                                <div className="overlay">
                                                    <div className="overlay-content">
                                                        <h3>Coretastic Crunch</h3>
                                                        <div className='nbn'>
                                                            <p> SHAKE THE ABS  </p>
                                                            <p >  Intensity:<span style={{ color: '#6A6665', fontWeight: '600' }}> INTERMEDIATE LEVEL </span></p></div>
                                                        <div className='bnb'>
                                                            This reformer workout targets the deep muscles of the abdomen back and pelvis. This class focuses on maintaining proper form and alignment using your breath to support movements, and engaging your deep core muscles, including your obliques and transverse abs. Building strength in the core, from top to bottom + front to back, will provide you with better posture, strong breathing pattern, and an increase in balance+ stability. Plus, everyone loves working
                                                            their abs. Right?

                                                        </div><br />
                                                        <p className='vbv'> To make your booking, please call/sms to +91 9667744326, 8595268566</p>

                                                        <i class="fa fa-times fa*3x close-button " onClick={closeModal} aria-hidden="true"></i>

                                                    </div>
                                                </div>}
                                        </div></div>
                                </div>
                                <div className="even-row table-content-row">
                                    <div className="square-block time-block">
                                        <div className="text-div">06:00 PM<br />to<br />07:00 PM</div>
                                        <div className="book-div">BOOK NOW</div>
                                    </div>
                                    <div className="table-block square-block odd-block">
                                        <div className="text-div">TRX Functional &amp; Reformer</div>
                                        <div className="book-div"><p onClick={toggleOverlay}>View Details</p>
                                            {isOverlayVisible && (
                                                <div className="overlay">
                                                    <div className="overlay-content">
                                                        <h3>TRX FUNCTIONAL & REFORMER
                                                        </h3>
                                                        <div className='nbn'>
                                                            <p>Build Strength & Muscle
                                                            </p>
                                                            <p >  Intensity:<span style={{ color: '#6A6665', fontWeight: '600' }}> INTERMEDIATE LEVEL</span></p></div>
                                                        <div className='bnb'>
                                                            A Reformer based Pilates workout is combined with the incredibly effective TRX® suspension training system. Efficient and precise routines are performed to enhance strength, coordination, mobility and balance. An excellent choice for sports lovers who want to challenge their limits and enjoy muscle building exercises in quick transitions.
                                                        </div><br />
                                                        <p className='vbv'> To make your booking, please call/sms to +91 9667744326, 8595268566</p>
                                                        <i class="fa fa-times fa*3x close-button " onClick={closeModal} aria-hidden="true"></i>
                                                    </div>
                                                </div>
                                            )}
                                        </div>
                                    </div>
                                    <div className="table-block square-block even-block">
                                        <div className="text-div">Burnout</div>
                                        <div className="book-div"><p onClick={toggleOverlay}>View Details</p>
                                            {isOverlayVisible && (
                                                <div className="overlay">
                                                    <div className="overlay-content">
                                                        <h3> BURNOUT

                                                        </h3>
                                                        <div className='nbn'>
                                                            <p> Lets Prove Our Endurance

                                                            </p>
                                                            <p >  Intensity:<span style={{ color: '#6A6665', fontWeight: '600' }}> INTERMEDIATE LEVEL</span></p></div>
                                                        <div className='bnb'>
                                                            A high-intensity endurance class aimed to burn maximum body fat working on bigger muscle groups. Expect squats, planks, challenging arm work, and compound exercises throughout this routine to keep your heart racing. The best workout to get toned and sculpted with long & lean muscles.
                                                        </div><br />
                                                        <p className='vbv'> To make your booking, please call/sms to +91 9667744326, 8595268566</p>

                                                        <i class="fa fa-times fa*3x close-button " onClick={closeModal} aria-hidden="true"></i>

                                                    </div>
                                                </div>
                                            )}
                                        </div>                                    </div>
                                    <div className="table-block square-block odd-block">
                                        <div className="text-div">Chair Balance Reformer</div>
                                        <div className="book-div"><p onClick={toggleOverlay}>View Details</p>
                                            {isOverlayVisible && (
                                                <div className="overlay">
                                                    <div className="overlay-content">
                                                        <h3> CHAIR BALANCE REFORMER
                                                        </h3>
                                                        <div className='nbn'>
                                                            <p> Focus On Core And Balance
                                                            </p>
                                                            <p >  Intensity:<span style={{ color: '#6A6665', fontWeight: '600' }}> OPEN TO ALL LEVELS</span></p></div>
                                                        <div className='bnb'>
                                                            Great core stability workout routines performed on Wunda chairs to achieve maximum core
                                                            strength, muscular endurance and balance. Added choreography with this challenging and
                                                            complex Pilates' apparatus promises a high intensity and heart pumping workout. This
                                                            workout requires a strong sense of mind-body connection and breathing technique to help
                                                            gain maximum results!
                                                        </div><br />
                                                        <p className='vbv'> To make your booking, please call/sms to +91 9667744326, 8595268566</p>

                                                        <i class="fa fa-times fa*3x close-button " onClick={closeModal} aria-hidden="true"></i>

                                                    </div>
                                                </div>
                                            )}
                                        </div>                                    </div>
                                    <div className="table-block square-block even-block">
                                        <div className="text-div">Signature Full Body Reformer</div>
                                        <div className="book-div"><p onClick={toggleOverlay}>View Details</p>
                                            {isOverlayVisible &&
                                                <div className="overlay">
                                                    <div className="overlay-content">
                                                        <h3> SIGNATURE FULL BODY REFORMER</h3>
                                                        <div className='nbn'>
                                                            <p> Strengthen & Lengthen</p>
                                                            <p >  Intensity:<span style={{ color: '#6A6665', fontWeight: '600' }}> OPEN TO ALL LEVELS</span></p></div>
                                                        <div className='bnb'>
                                                            Designed for all, this strengthen and lengthen workout session will help you to build stamina,
                                                            flexibility, coordination, and strong balance. This session are done using Reformer machine, stability chair,
                                                            TRX, magic rings and other Pilates props for you to experience variations in fun and challenging ways. Experience
                                                            the benefits of Pilates with a twist.</div><br />
                                                        <p className='vbv'> To make your booking, please call/sms to +91 9667744326, 8595268566</p>

                                                        <i class="fa fa-times fa*3x close-button " onClick={closeModal} aria-hidden="true"></i>

                                                    </div>
                                                </div>}
                                        </div>
                                    </div>
                                    <div className="table-block square-block odd-block">
                                        <div className="table-block square-block even-block">
                                            <div className="text-div">Bosu Balance And Reformer </div>
                                            <div className="book-div"><p onClick={toggleOverlay}>View Details</p>
                                                {isOverlayVisible &&
                                                    <div className="overlay">
                                                        <div className="overlay-content">
                                                            <h3>Bosu Balance And Reformer</h3>
                                                            <div className='nbn'>
                                                                <p> PUSHING BOUNDRIES AND TESTING STABILITY </p>
                                                                <p >  Intensity:<span style={{ color: '#6A6665', fontWeight: '600' }}> INTERMEDIATE LEVEL </span></p></div>
                                                            <div className='bnb'>Strengthening the Stabilizer Muscles. Try this class if you want an exercise routine to be more challenging and fun. Bosu trainers are versatile enough to use for stretching, balance-training, rehabilitat­-ion and even strength-training and plyometric training. Bosu trainer in combination with Functional training, TRX training and Free weights force your core to work constantly throughout the entire motion. This is an excellent way to make easy exercises more challenging.

                                                            </div><br />
                                                            <p className='vbv'> To make your booking, please call/sms to +91 9667744326, 8595268566</p>

                                                            <i class="fa fa-times fa*3x close-button " onClick={closeModal} aria-hidden="true"></i>

                                                        </div>
                                                    </div>}
                                            </div></div>
                                    </div>
                                    <div className="table-block square-block even-block">
                                        <div className="text-div">Burnout</div>
                                        <div className="book-div"><p onClick={toggleOverlay}>View Details</p>
                                            {isOverlayVisible && (
                                                <div className="overlay">
                                                    <div className="overlay-content">
                                                        <h3> BURNOUT

                                                        </h3>
                                                        <div className='nbn'>
                                                            <p> Lets Prove Our Endurance

                                                            </p>
                                                            <p >  Intensity:<span style={{ color: '#6A6665', fontWeight: '600' }}> INTERMEDIATE LEVEL</span></p></div>
                                                        <div className='bnb'>
                                                            A high-intensity endurance class aimed to burn maximum body fat working on bigger muscle groups. Expect squats, planks, challenging arm work, and compound exercises throughout this routine to keep your heart racing. The best workout to get toned and sculpted with long & lean muscles.
                                                        </div><br />
                                                        <p className='vbv'> To make your booking, please call/sms to +91 9667744326, 8595268566</p>

                                                        <i class="fa fa-times fa*3x close-button " onClick={closeModal} aria-hidden="true"></i>

                                                    </div>
                                                </div>
                                            )}
                                        </div>                                    </div>
                                    <div className="table-block square-block odd-block">
                                        <div className="text-div"></div>
                                        <div className="book-div">No classNamees</div>
                                    </div>
                                </div>
                                <div className="odd-row table-content-row">
                                    <div className="square-block time-block">
                                        <div className="text-div">07:00 PM<br />to<br />08:00 PM</div>
                                        <div className="book-div">BOOK NOW</div>
                                    </div>
                                    <div className="table-block square-block odd-block">
                                        <div className="text-div">Chair Balance Reformer</div>
                                        <div className="book-div"><p onClick={toggleOverlay}>View Details</p>
                                            {isOverlayVisible && (
                                                <div className="overlay">
                                                    <div className="overlay-content">
                                                        <h3> CHAIR BALANCE REFORMER
                                                        </h3>
                                                        <div className='nbn'>
                                                            <p> Focus On Core And Balance
                                                            </p>
                                                            <p >  Intensity:<span style={{ color: '#6A6665', fontWeight: '600' }}> OPEN TO ALL LEVELS</span></p></div>
                                                        <div className='bnb'>
                                                            Great core stability workout routines performed on Wunda chairs to achieve maximum core
                                                            strength, muscular endurance and balance. Added choreography with this challenging and
                                                            complex Pilates' apparatus promises a high intensity and heart pumping workout. This
                                                            workout requires a strong sense of mind-body connection and breathing technique to help
                                                            gain maximum results!
                                                        </div><br />
                                                        <p className='vbv'> To make your booking, please call/sms to +91 9667744326, 8595268566</p>

                                                        <i class="fa fa-times fa*3x close-button " onClick={closeModal} aria-hidden="true"></i>

                                                    </div>
                                                </div>
                                            )}
                                        </div>                                    </div>
                                    <div className="table-block square-block even-block">
                                        <div className="text-div">Signature Full Body Reformer</div>
                                        <div className="book-div"><p onClick={toggleOverlay}>View Details</p>
                                            {isOverlayVisible &&
                                                <div className="overlay">
                                                    <div className="overlay-content">
                                                        <h3> SIGNATURE FULL BODY REFORMER</h3>
                                                        <div className='nbn'>
                                                            <p> Strengthen & Lengthen</p>
                                                            <p >  Intensity:<span style={{ color: '#6A6665', fontWeight: '600' }}> OPEN TO ALL LEVELS</span></p></div>
                                                        <div className='bnb'>
                                                            Designed for all, this strengthen and lengthen workout session will help you to build stamina,
                                                            flexibility, coordination, and strong balance. This session are done using Reformer machine, stability chair,
                                                            TRX, magic rings and other Pilates props for you to experience variations in fun and challenging ways. Experience
                                                            the benefits of Pilates with a twist.</div><br />
                                                        <p className='vbv'> To make your booking, please call/sms to +91 9667744326, 8595268566</p>

                                                        <i class="fa fa-times fa*3x close-button " onClick={closeModal} aria-hidden="true"></i>

                                                    </div>
                                                </div>}
                                        </div>                                    </div>
                                    <div className="table-block square-block odd-block">
                                        <div className="text-div">Cardio Interval Pilates</div>
                                        <div className="book-div"><p onClick={toggleOverlay}>View Details</p>
                                            {isOverlayVisible && (
                                                <div className="overlay">
                                                    <div className="overlay-content">
                                                        <h3> CARDIO INTERVAL PILATES</h3>
                                                        <div className='nbn'>
                                                            <p> Heart-Pumping Interval Training</p>
                                                            <p >  Intensity:<span style={{ color: '#6A6665', fontWeight: '600' }}> INTERMEDIATE  TO ADVANCE LEVEL</span></p></div>
                                                        <div className='bnb'>
                                                            A high-intensity, calorie-blasting cardio workout with a combination of Jumpboard Reformer based strength training is what makes up this energetic class. This high-impact, interval training workout session is dynamic and fast-paced. A solid Pilates foundation is required to join this class, minimum 5 to 6 prior Pilate sessions would be ideal.</div><br />
                                                        <p className='vbv'> To make your booking, please call/sms to +91 9667744326, 8595268566</p>

                                                        <i class="fa fa-times fa*3x close-button " onClick={closeModal} aria-hidden="true"></i>

                                                    </div>
                                                </div>
                                            )}
                                        </div>
                                    </div>
                                    <div className="table-block square-block even-block">
                                        <div className="text-div">TRX Functional &amp; Reformer</div>
                                        <div className="book-div"><p onClick={toggleOverlay}>View Details</p>
                                            {isOverlayVisible && (
                                                <div className="overlay">
                                                    <div className="overlay-content">
                                                        <h3>TRX FUNCTIONAL & REFORMER
                                                        </h3>
                                                        <div className='nbn'>
                                                            <p>Build Strength & Muscle
                                                            </p>
                                                            <p >  Intensity:<span style={{ color: '#6A6665', fontWeight: '600' }}> INTERMEDIATE LEVEL</span></p></div>
                                                        <div className='bnb'>
                                                            A Reformer based Pilates workout is combined with the incredibly effective TRX® suspension training system. Efficient and precise routines are performed to enhance strength, coordination, mobility and balance. An excellent choice for sports lovers who want to challenge their limits and enjoy muscle building exercises in quick transitions.
                                                        </div><br />
                                                        <p className='vbv'> To make your booking, please call/sms to +91 9667744326, 8595268566</p>
                                                        <i class="fa fa-times fa*3x close-button " onClick={closeModal} aria-hidden="true"></i>
                                                    </div>
                                                </div>
                                            )}
                                        </div>
                                    </div>
                                    <div className="table-block square-block odd-block">
                                        <div className="text-div">Chair Balance Reformer</div>
                                        <div className="book-div"><p onClick={toggleOverlay}>View Details</p>
                                            {isOverlayVisible && (
                                                <div className="overlay">
                                                    <div className="overlay-content">
                                                        <h3> CHAIR BALANCE REFORMER
                                                        </h3>
                                                        <div className='nbn'>
                                                            <p> Focus On Core And Balance
                                                            </p>
                                                            <p >  Intensity:<span style={{ color: '#6A6665', fontWeight: '600' }}> OPEN TO ALL LEVELS</span></p></div>
                                                        <div className='bnb'>
                                                            Great core stability workout routines performed on Wunda chairs to achieve maximum core
                                                            strength, muscular endurance and balance. Added choreography with this challenging and
                                                            complex Pilates' apparatus promises a high intensity and heart pumping workout. This
                                                            workout requires a strong sense of mind-body connection and breathing technique to help
                                                            gain maximum results!
                                                        </div><br />
                                                        <p className='vbv'> To make your booking, please call/sms to +91 9667744326, 8595268566</p>

                                                        <i class="fa fa-times fa*3x close-button " onClick={closeModal} aria-hidden="true"></i>

                                                    </div>
                                                </div>
                                            )}
                                        </div>                                    </div>
                                    <div className="table-block square-block even-block">
                                        <div className="text-div">Signature Full Body Reformer</div>
                                        <div className="book-div"><p onClick={toggleOverlay}>View Details</p>
                                            {isOverlayVisible &&
                                                <div className="overlay">
                                                    <div className="overlay-content">
                                                        <h3> SIGNATURE FULL BODY REFORMER</h3>
                                                        <div className='nbn'>
                                                            <p> Strengthen & Lengthen</p>
                                                            <p >  Intensity:<span style={{ color: '#6A6665', fontWeight: '600' }}> OPEN TO ALL LEVELS</span></p></div>
                                                        <div className='bnb'>
                                                            Designed for all, this strengthen and lengthen workout session will help you to build stamina,
                                                            flexibility, coordination, and strong balance. This session are done using Reformer machine, stability chair,
                                                            TRX, magic rings and other Pilates props for you to experience variations in fun and challenging ways. Experience
                                                            the benefits of Pilates with a twist.</div><br />
                                                        <p className='vbv'> To make your booking, please call/sms to +91 9667744326, 8595268566</p>

                                                        <i class="fa fa-times fa*3x close-button " onClick={closeModal} aria-hidden="true"></i>

                                                    </div>
                                                </div>}
                                        </div>
                                    </div>
                                    <div className="table-block square-block odd-block">
                                        <div className="text-div"></div>
                                        <div className="book-div">No classNamees</div>
                                    </div>
                                </div>
                                <div className="even-row table-content-row">
                                    <div className="square-block time-block">
                                        <div className="text-div">08:00 PM<br />to<br />09:00 PM</div>
                                        <div className="book-div">BOOK NOW</div>
                                    </div>
                                    <div className="table-block square-block odd-block">
                                        <div className="text-div">Signature Full Body Reformer</div>
                                        <div className="book-div"><p onClick={toggleOverlay}>View Details</p>
                                            {isOverlayVisible &&
                                                <div className="overlay">
                                                    <div className="overlay-content">
                                                        <h3> SIGNATURE FULL BODY REFORMER</h3>
                                                        <div className='nbn'>
                                                            <p> Strengthen & Lengthen</p>
                                                            <p >  Intensity:<span style={{ color: '#6A6665', fontWeight: '600' }}> OPEN TO ALL LEVELS</span></p></div>
                                                        <div className='bnb'>
                                                            Designed for all, this strengthen and lengthen workout session will help you to build stamina,
                                                            flexibility, coordination, and strong balance. This session are done using Reformer machine, stability chair,
                                                            TRX, magic rings and other Pilates props for you to experience variations in fun and challenging ways. Experience
                                                            the benefits of Pilates with a twist.</div><br />
                                                        <p className='vbv'> To make your booking, please call/sms to +91 9667744326, 8595268566</p>

                                                        <i class="fa fa-times fa*3x close-button " onClick={closeModal} aria-hidden="true"></i>

                                                    </div>
                                                </div>}
                                        </div>                                    </div>
                                    <div className="table-block square-block even-block">
                                        <div className="text-div">Coretastic Crunch</div>
                                        <div className="book-div"><p onClick={toggleOverlay}>View Details</p>
                                            {isOverlayVisible &&
                                                <div className="overlay">
                                                    <div className="overlay-content">
                                                        <h3>Coretastic Crunch</h3>
                                                        <div className='nbn'>
                                                            <p> SHAKE THE ABS  </p>
                                                            <p >  Intensity:<span style={{ color: '#6A6665', fontWeight: '600' }}> INTERMEDIATE LEVEL </span></p></div>
                                                        <div className='bnb'>
                                                            This reformer workout targets the deep muscles of the abdomen back and pelvis. This class focuses on maintaining proper form and alignment using your breath to support movements, and engaging your deep core muscles, including your obliques and transverse abs. Building strength in the core, from top to bottom + front to back, will provide you with better posture, strong breathing pattern, and an increase in balance+ stability. Plus, everyone loves working
                                                            their abs. Right?

                                                        </div><br />
                                                        <p className='vbv'> To make your booking, please call/sms to +91 9667744326, 8595268566</p>

                                                        <i class="fa fa-times fa*3x close-button " onClick={closeModal} aria-hidden="true"></i>

                                                    </div>
                                                </div>}
                                        </div></div> <div className="table-block square-block odd-block">
                                        <div className="text-div">Chair Balance Reformer</div>
                                        <div className="book-div"><p onClick={toggleOverlay}>View Details</p>
                                            {isOverlayVisible && (
                                                <div className="overlay">
                                                    <div className="overlay-content">
                                                        <h3> CHAIR BALANCE REFORMER
                                                        </h3>
                                                        <div className='nbn'>
                                                            <p> Focus On Core And Balance
                                                            </p>
                                                            <p >  Intensity:<span style={{ color: '#6A6665', fontWeight: '600' }}> OPEN TO ALL LEVELS</span></p></div>
                                                        <div className='bnb'>
                                                            Great core stability workout routines performed on Wunda chairs to achieve maximum core
                                                            strength, muscular endurance and balance. Added choreography with this challenging and
                                                            complex Pilates' apparatus promises a high intensity and heart pumping workout. This
                                                            workout requires a strong sense of mind-body connection and breathing technique to help
                                                            gain maximum results!
                                                        </div><br />
                                                        <p className='vbv'> To make your booking, please call/sms to +91 9667744326, 8595268566</p>

                                                        <i class="fa fa-times fa*3x close-button " onClick={closeModal} aria-hidden="true"></i>

                                                    </div>
                                                </div>
                                            )}
                                        </div>                                    </div>
                                    <div className="table-block square-block even-block">
                                        <div className="text-div">Signature Full Body Reformer</div>
                                        <div className="book-div"><p onClick={toggleOverlay}>View Details</p>
                                            {isOverlayVisible &&
                                                <div className="overlay">
                                                    <div className="overlay-content">
                                                        <h3> SIGNATURE FULL BODY REFORMER</h3>
                                                        <div className='nbn'>
                                                            <p> Strengthen & Lengthen</p>
                                                            <p >  Intensity:<span style={{ color: '#6A6665', fontWeight: '600' }}> OPEN TO ALL LEVELS</span></p></div>
                                                        <div className='bnb'>
                                                            Designed for all, this strengthen and lengthen workout session will help you to build stamina,
                                                            flexibility, coordination, and strong balance. This session are done using Reformer machine, stability chair,
                                                            TRX, magic rings and other Pilates props for you to experience variations in fun and challenging ways. Experience
                                                            the benefits of Pilates with a twist.</div><br />
                                                        <p className='vbv'> To make your booking, please call/sms to +91 9667744326, 8595268566</p>

                                                        <i class="fa fa-times fa*3x close-button " onClick={closeModal} aria-hidden="true"></i>

                                                    </div>
                                                </div>}
                                        </div>                                    </div>
                                    <div className="table-block square-block odd-block">
                                        <div className="text-div">Cardio Interval Pilates</div>
                                        <div className="book-div"><p onClick={toggleOverlay}>View Details</p>
                                            {isOverlayVisible && (
                                                <div className="overlay">
                                                    <div className="overlay-content">
                                                        <h3> CARDIO INTERVAL PILATES</h3>
                                                        <div className='nbn'>
                                                            <p> Heart-Pumping Interval Training</p>
                                                            <p >  Intensity:<span style={{ color: '#6A6665', fontWeight: '600' }}> INTERMEDIATE  TO ADVANCE LEVEL</span></p></div>
                                                        <div className='bnb'>
                                                            A high-intensity, calorie-blasting cardio workout with a combination of Jumpboard Reformer based strength training is what makes up this energetic class. This high-impact, interval training workout session is dynamic and fast-paced. A solid Pilates foundation is required to join this class, minimum 5 to 6 prior Pilate sessions would be ideal.</div><br />
                                                        <p className='vbv'> To make your booking, please call/sms to +91 9667744326, 8595268566</p>

                                                        <i class="fa fa-times fa*3x close-button " onClick={closeModal} aria-hidden="true"></i>

                                                    </div>
                                                </div>
                                            )}
                                        </div>
                                    </div>
                                    <div className="table-block square-block even-block">
                                        <div className="text-div">Upper Body Blast </div>
                                        <div className="book-div"><p onClick={toggleOverlay}>View Details</p>
                                            {isOverlayVisible &&
                                                <div className="overlay">
                                                    <div className="overlay-content">
                                                        <h3>Upper Body Blast</h3>
                                                        <div className='nbn'>
                                                            <p> SLEEK AND SCULPTED  </p>
                                                            <p >  Intensity:<span style={{ color: '#6A6665', fontWeight: '600' }}>OPEN TO ALL LEVELS </span></p></div>
                                                        <div className='bnb'>
                                                            This reformer workout targets the entire upper body with a mix of fun and challenging exercises. This class focuses on building strength in the entire upper body including the arms, shoulders, chest, triceps, and more.Different props are used in various ways to encourage proper form,engagement, and add a great spin to any movement.
                                                            best way!

                                                        </div><br />
                                                        <p className='vbv'> To make your booking, please call/sms to +91 9667744326, 8595268566</p>

                                                        <i class="fa fa-times fa*3x close-button " onClick={closeModal} aria-hidden="true"></i>

                                                    </div>
                                                </div>}
                                        </div></div>
                                    <div className="table-block square-block odd-block">
                                        <div className="text-div"></div>
                                        <div className="book-div">No classNamees</div>
                                    </div>
                                </div>
                            </div>

                        </div>
                    </div>
                </div>
            </div >
            {/* <div className='mmm'>
                <p className='mmmm'>For Private sessions, please <Link onClick={scrollToTop} to='/contactus' style={{textDecoration:'none'}}>Contact Us.</Link> </p>
            </div> */}


        </>
    )
}

export default Images;

import React from 'react'
import { Accordion } from 'react-bootstrap'
import ImageGridd from './Components/GKC.js'



const GK = () => {
    return (
        <>
        <section id='noida2'>
            <div className='noida'>
                <div className='noida1'>
                    <h4>Best Pilates Studio in GREATER KAILASH 1
                        <hr className='noida11' />
                    </h4>
                    <div className='noida2'>
                    <iframe src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d3504.6678628130016!2d77.22876588108298!3d28.549701325226806!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x390ce3b2eed79149%3A0xc2ca913cdf94f67f!2sCorefit%20Plus%20%7C%20Pilates%20Studio%20in%20Greater%20Kailash%201!5e0!3m2!1sen!2sin!4v1693999824624!5m2!1sen!2sin" title='GKMap' allowFullScreen="" loading="lazy" referrerPolicy="no-referrer-when-downgrade"></iframe>                    </div>
                    <div className='noida3'>
                        <Accordion defaultActiveKey="0">
                            <Accordion.Item eventKey="0">
                                <Accordion.Header ><h3>GREATER KAILASH-1</h3></Accordion.Header>
                                <Accordion.Body >
                                    <p> S- 15,
                                        S Block,
                                        Greater Kailash-1, New Delhi,
                                        Delhi 110048
                                    </p>  </Accordion.Body>
                            </Accordion.Item>
                        </Accordion>
                    </div>
                    <div className='noida23'> <h4>Studio Gallery                     <hr className='noida11' />
                        </h4>
                        <ImageGridd/>
                    {/* <div className='noida4'>

                       
                        <div className='noida5'>
                            <div className='noida6'>
                                <img src={GKImg1} alt='xxx' />
                            </div>
                            <div className='noida7'>
                                <img src={GKImg2} alt='xxx' />

                            </div>
                            <div className='noida8'>
                                <img src={GKImg3} alt='xxx' />

                            </div>
                            <div className='noida9'>
                                <img src={GKImg4} alt='xxx' />

                            </div>
                            <div className='noida10'>
                                <img src={GKimg5} alt='xxx' />

                            </div>
                        </div>
                        <div className='noida5'>
                            <div className='noida6'>
                                <img src={GKimg6} alt='xxx' />

                            </div>
                            <div className='noida7'>
                                <img src={GKimg7} alt='xxx' />

                            </div>
                            <div className='noida8'>
                                <img src={GKimg8} alt='xxx' />

                            </div>
                            <div className='noida9'>
                                <img src={GKimg9} alt='xxx' />

                            </div>
                            <div className='noida10'>
                                <img src={GKimg10} alt='xxx' />

                            </div>
                        </div>

                    </div> */}
                </div></div>


                </div></section>
        </>
    )
}

export default GK
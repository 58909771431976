import React from 'react'
import './Main1.css'
import Img2 from "../Images/Workout Homepage.webp"
import Img1 from '../Images/our studios Home Page.webp'
import Img3 from '../Images/GP Updated Gallery.webp'



const Main2 = () => {


    const openGoogleForm = () => {
        window.open('https://docs.google.com/forms/d/e/1FAIpQLSfN8fxItN5Urk_rTdkPv4yxDiFXCEHE_N915BOLlop3OhQBMA/viewform?usp=sf_link', '_blank');
    };


    return (
        <>

            <div className='Main'>
                <p className='m1'>Strong to the Core</p>
                <button onClick={openGoogleForm} className='btno'>Book a Trial Now</button>
            </div>
            <div className='Main2'>
                <p className='m2'> Welcome to <span style={{ fontFamily: "'Demibolds',sans-serif", color: 'black' }}>Corefit Plus</span> </p>
            </div>
            <div className='Main3'>
                <p className='m3'>Discover the power of Pilates, a holistic fitness practice that focuses on core strength, flexibility, and mind-body connection. Pilates can help improve posture, increase energy, and enhance overall well-being.</p>
            </div>
            <div className='main55'>
                <div className='Main2Img'>
                    <div className='Img1'>
                        <img src={Img1} alt='xxx' />
                        <p className='m40'>Our Studio</p>
                        <p className='m50'>Step into our inviting studio, thoughtfully designed to inspire your practice. You'll find state-of-the-art Pilates equipment, a tranquil ambiance, and a supportive community of fellow fitness enthusiasts. Here, you can escape the stresses of daily life and focus on nurturing your body and mind.</p>
                    </div>
                </div>
                <div className='Main2Img'>
                    <div className='Img1'>
                        <img src={Img2} alt='xxx' />
                        <p className='m40'>The Workout</p>
                        <p className='m50'>At Corefit Plus Pilates Studio, we offer a diverse range of Pilates classes designed to cater to your individual fitness needs and goals. Whether you're a beginner or an experienced practitioner, our classes are tailored towards every fitness level, age and goal.Using specialized equipment, you'll experience a full-body workout that targets specific muscle groups. The equipment adds resistance, providing a dynamic and challenging workout.</p>
                    </div>
                </div>
                <div className='Main2Img'>
                    <div className='Img1'>
                        <img src={Img3} alt='xxx' />
                        <p className='m40'>The Community</p>
                        <p className='m50'> Each instructor at Corefit Plus believes that wellness is not just about losing weight or achieving physical fitness - it's a holistic journey that encompasses emotional health, mental well-being, and more.</p>
                    </div>
                </div>
            </div>

        </>



    )
}

export default Main2
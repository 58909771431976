import React from 'react'
import './Main2.css'
import Img from '../Images/ic_bullet-green.svg.png'
const Main2 = () => {
    return (


        <>
            <div>

                <div id="philosophy">
                    <div className="title">
                        <span className="title-1">Corefit Plus</span>
                        <span className="title-2">Philosophy</span>
                    </div>
                    <div className="divider">

                    </div>
                    <div className="philo-desc">
                    At the core of our philosophy is the unwavering commitment to your well-being. Our Pilates instructors are passionate about guiding you on a journey to better health, enhanced flexibility, and increased vitality. We're not just a fitness studio; we're a community dedicated to helping you become the best version of yourself.At Corefit Plus Pilates Studio, we stand out for a multitude of reasons:                    </div>
                    <div className="plan-details">
                        <div className="plan-det">
                            <div>
                                <ul>
                                    <li>
                                        <div className="plan-con">
                                            <img src={Img} alt="arrow" />
                                            <p>
                                           <span className='lol'> Personalized Attention:</span> We understand that every fitness journey is unique and our approach is centered on providing personalized attention to help you achieve your goals.
                                                </p>
                                        </div>
                                    </li>
                                   
                                    <li>
                                        <div className="plan-con">
                                            <img src={Img} alt="arrow" />
                                            <p>
                                            <span className='lol'>  Flexible Booking App:</span> Our user-friendly app allows you to book classes based on your availability, offering total flexibility to fit Pilates into your schedule seamlessly.
                                                </p>
                                        </div>
                                    </li>
                                </ul>
                            </div>
                        </div>
                        <div className="plan-det-col2">
                            <div>
                                <ul>
                                    <li>
                                        <div className="plan-con">
                                            <img src={Img} alt="arrow" />
                                            <p>
                                            <span className='lol'>  Diverse Class Selection:</span> We offer an extensive array of Pilates classes, ensuring you never get bored. Choose from various class types and instructors to keep your workouts exciting and engaging.
                                            </p>
                                        </div>
                                    </li>
                                    <li>
                                        <div className="plan-con">
                                            <img src={Img} alt="arrow" />
                                            <p>
                                            <span className='lol'> Expert Instructors:</span> Our team comprises of experienced trainers who are dedicated to helping you succeed. You have the freedom to switch trainers or class styles at any time, providing a well-rounded Pilates experience.                                                </p>
                                        </div>
                                    </li>
                                    </ul>
                            </div>
                        </div>
                    </div>

                    </div>
                    </div>

                </>
                )
}

                export default Main2;
import React, { useState, useEffect } from 'react';
import './Main4.css'
import Img12 from '../Images/ic_gift.svg.png'
import Img13 from '../Images/ic_invite-friend.svg.png'
import PlanImg from '../Images/membership_plan_south-delhi.webp'
import PlanImgDelhi from '../Images/membership_plan_noida.webp'
import mkmk from '../Images/group-pilates-instructors-exercising-reformers.jpg.png'
import Modal from 'react-bootstrap/Modal';
import header from "../Images/Website Banner Header .png";
import PlanImg2 from '../Images/membership_plan_South_delhi-mobile.webp';
import PlanImg2Delhi from "../Images/membership_plan_noida-mobile.webp";



function MyVerticallyCenteredModal(props) {
    const openGoogleForm = () => {
        window.open('https://docs.google.com/forms/d/e/1FAIpQLSfN8fxItN5Urk_rTdkPv4yxDiFXCEHE_N915BOLlop3OhQBMA/viewform?usp=sf_link', '_blank');
    };

    const [screenWidth, setScreenWidth] = useState(window.innerWidth);

    useEffect(() => {
        const handleResize = () => {
            setScreenWidth(window.innerWidth);
        };

        window.addEventListener('resize', handleResize);

        return () => {
            window.removeEventListener('resize', handleResize);
        };
    }, []);

    let PlanImgComponent;

    if (screenWidth < 768) {
        PlanImgComponent = <img onClick={openGoogleForm} src={PlanImg2} alt='main4img' className='Plan' />;
    } else {
        PlanImgComponent = <img onClick={openGoogleForm} src={PlanImg} alt='main4img' className='Plan' />;
    }





    return (
        <Modal
            {...props}
            size="lg"
            aria-labelledby="contained-modal-title-vcenter"
            centered
        >
            <Modal.Header className='model' closeButton>
            </Modal.Header>
            <Modal.Body className='model'>
                {PlanImgComponent}
            </Modal.Body>
        </Modal>
    );
}
function MyVerticallyCenteredModalDelhi(props) {
    const openGoogleForm = () => {
        window.open('https://docs.google.com/forms/d/e/1FAIpQLSfN8fxItN5Urk_rTdkPv4yxDiFXCEHE_N915BOLlop3OhQBMA/viewform?usp=sf_link', '_blank');
    };

    const [screenWidth, setScreenWidth] = useState(window.innerWidth);

    useEffect(() => {
        const handleResize = () => {
            setScreenWidth(window.innerWidth);
        };

        window.addEventListener('resize', handleResize);

        return () => {
            window.removeEventListener('resize', handleResize);
        };
    }, []);

    let PlanImgComponent;

    if (screenWidth < 768) {
        PlanImgComponent = <img onClick={openGoogleForm} src={PlanImg2Delhi} alt='main4img' className='Plan' />;
    } else {
        PlanImgComponent = <img onClick={openGoogleForm} src={PlanImgDelhi} alt='main4img' className='Plan' />;
    }





    return (
        <Modal
            {...props}
            size="lg"
            aria-labelledby="contained-modal-title-vcenter"
            centered
        >
            <Modal.Header className='model' closeButton>
            </Modal.Header>
            <Modal.Body className='model'>
                {PlanImgComponent}
            </Modal.Body>
        </Modal>
    );
}

const Main4 = () => {

    const phoneNumber = '9625474665';
    const message = 'I am interested in learning more about your Pilates Instructor Training Program.';
    const whatsappLink = `https://wa.me/${phoneNumber}?text=${encodeURIComponent(message)}`;

    const openGoogleForm2 = () => {
        window.open('https://docs.google.com/forms/d/e/1FAIpQLSfN8fxItN5Urk_rTdkPv4yxDiFXCEHE_N915BOLlop3OhQBMA/viewform?usp=sf_link', '_blank');
    };
    const [modalShow, setModalShow] = React.useState(false);
    const [modalShowdelhi, setModalShowDelhi] = React.useState(false);

    return (
        <>
            <div className='m04'>
                <div className='m4'>
                    <p className='m41'>Pilates Instructor Training Program</p>
                    <p className='m42'>Turn your <span style={{ fontFamily: 'BlueL', fontSize: '26px', color: '#D7BD92' }}> passion</span> into<br />
                        your Career!</p>
                    <p className='m43'>
                        At Corefit Plus Pilates Studio, we believe in helping you turn your passion for wellness into a fulfilling career. Our comprehensive Pilates instructor training programs includes both mat and reformer Pilates. You will be trained by the best instructor who will mentor you on your education path. Join us and let your love for Pilates become the foundation of your career journey.
                    </p>

                    <a href={whatsappLink} target="_blank" rel="noopener noreferrer">

                        <button className='m44'>  Enquire Now</button></a>
                </div>
                <div className='m0040'>
                    <img src={mkmk} alt='main4img' />
                </div>
            </div>

            <div className='op'>
                <img src={header} alt='main4img' />
                <div className='text-box3'>

                    <p className='op1'>Pricing & Membership</p>
                    <div className='pricing-actions'>
                    <button className='op3' onClick={() => setModalShow(true)}>Get Details (South Delhi)</button>
                    <MyVerticallyCenteredModal
                        show={modalShow}
                        onHide={() => setModalShow(false)}
                        />
                    <button className='op3' onClick={() => setModalShowDelhi(true)}>Get Details (Noida)</button>
                    <MyVerticallyCenteredModalDelhi
                        show={modalShowdelhi}
                        onHide={() => setModalShowDelhi(false)}
                        />
                    </div>

                </div>
            </div>
            <div className='po'>
                <div className='abhi'>
                    <p className='po1'>Gift of <span style={{ fontFamily: "BlueL", fontSize: '25px', color: '#D7BD92' }}> Health</span></p>
                    <hr className='ere' />

                    <div className='po2'>Look for gifts that get people moving. During celebrations, holidays, or at any
                        time of the year, this thoughtful gift that provides health benefits can have the most
                        lasting impact.</div>

                </div>
                <div className='pp'>
                    <div className='image-text-container0'>
                        <img src={Img12} alt="main4img" />
                        <div className='text-boxx'>
                            <p className='pp1'>Gift a Voucher</p>
                            <p className='pp2'>In life, there are three things
                                that matter – your health, your
                                mission, and the people you love.</p>
                            <button onClick={openGoogleForm2}>GIFT NOW</button>
                        </div>
                    </div>

                    <div className='image-text-container1'>
                        <img src={Img13} alt="main4img" />
                        <div className='text-boxy'>
                            <p className='pp1'>Refer a Friend</p>
                            <p className='pp2' >Promote health & fitness to people you
                                care. Start by inviting them to join
                                in activities you enjoy.</p>
                            <button onClick={openGoogleForm2} className='pp4'>REFER NOW</button>
                        </div>
                    </div>

                </div>

            </div>
        </>
    )
}

export default Main4;
import React from 'react';
import './Home.css';
import Main1 from '../Components/Main1';
import Main2 from '../Components/Main2';
import Main3 from '../Components/Main3';
import Main4 from '../Components/Main4';
import Mainpage5 from '../Components/Mainpage5';
import Whatsapp from '../Components/Whatsapp';
import Popup1 from '../Components/Popup1';
import FaqWidget from './FaqWidget';
import HeroBanner from '../Components/HeroBanner';
import { Helmet } from 'react-helmet';
import { useEffect } from 'react';


const Home = () => {

  useEffect(() => {
    document.title = 'Corefitplus Pilates Studio | Best Pilates Studio in South Delhi & Noida';
  }, []);


  return (
    <>
      <Helmet>
        <title>Corefitplus Pilates Studio | Best Pilates Studio in South Delhi & Noida</title>
        <meta
          name="description"
          content="Are you looking for the best Pilates Workout Classes in GK1, Greenpark & Noida? Here is Corefit Plus Pilates Studio for Pilates classes. We are the best Pilates studio in Greater Kailash 1, Green park and Noida"
        />
      </Helmet>



      <FaqWidget />
      <Whatsapp />
      <HeroBanner />
      <Popup1 />
      <Main1 />
      <Main2 />
      <Main3 />
      <Main4 />
      <Mainpage5 />
    </>
  );
}

export default Home;

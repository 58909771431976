import React from 'react'
import './Footer.css'
import LinkImg from '../Images/Link - Corefit Plus ⏵ Untitled-design-11-1.png';
import { Link } from 'react-router-dom';
import mmm1 from '../Images/img_body_service_small.png'
import mmm2 from '../Images/img_foot_service_small.png'
import mmm3 from '../Images/img_head_service_small.png'
import mmm4 from '../Images/img_manicur_service_small.png'
import mmm5 from '../Images/aromothai_logo-foot.svg'
import Accordiann from '../Components/Accodiann'
import Massagepic from '../Images/aromothai_logo-small.svg'
import instaimg from '../Images/ic_facebook-quick.svg'
import fbimg from '../Images/ic_instagram-quick.svg'

const Footer = () => {
  const scrollToTop = () => {
    window.scrollTo(0, 0)
  }
  // const handle = (id) => {
  //   const element = document.getElementById(id);
  //   if (element) {
  //     element.scrollIntoView({ behavior: 'smooth' });
  //   }
  // }

  return (
    <>
      <div id='contactus'>
        <div className='foot-content'>
          <div className='col1'>
            <div className='core-logo'>
              <img src={LinkImg} alt="corefit-logo"></img>
            </div>
            <h6 style={{ color: 'white', padding: '20px 0px 10px 0px', fontSize: '14px', fontWeight: '600' }}>FOLLOW US</h6>
            <div className="logo-block">
              <div className="kiriyoga-logo">
              <a href='https://www.facebook.com/corefit.plus1/' ><i style={{color:'white'}} className="fa-brands fa-square-facebook fa-2x"></i></a>
                            </div>
              <div className="aya-logo">
              <a href='https://www.instagram.com/corefit.plus/' > <i style={{color:'white'}} className="fa-brands fa-instagram fa-2x"></i></a>
                           </div>
            </div>

          </div>

          <div className="col2">
            <div className="menu">
              <div className="menu-tile">menu</div>
              <ol>
                <li>
                  <Link onClick={scrollToTop} className="no-underline" to="/">HOME</Link>
                </li>
                <li>
                  <Link onClick={scrollToTop} className="no-underline" to="/classes">CLASSES</Link>
                </li>
               

                <li>
                  <Link onClick={scrollToTop} className="no-underline" to="/faqs">FAQs</Link>
                </li>
                <li>
                  <Link onClick={scrollToTop} className="no-underline" to="/term">TERMS AND SERVICES</Link>  </li>

                <li>
                  <Link onClick={scrollToTop} className="no-underline" to="/privacypolicy">PRIVACY POLICY</Link>
                </li>
              </ol></div></div>
          <div id="quick-menu">
            <div className="react-reveal row-outer buy-blk" >
              <a href='https://www.aromathai.com/' ><img className="aromo-logo" src={Massagepic} alt="aromo-logo" />
              </a>
            </div>
            <div className="react-reveal row-outer social-blk" >
              <a href='https://www.facebook.com/corefit.plus1/' ><img className="media-logo" src={fbimg} alt="insta" /></a>
              <a href='https://www.instagram.com/corefit.plus/' ><img className="media-logo" src={instaimg} alt="fb" /></a>
            </div>
          </div>


          <div className="col3">
            <h6 style={{ color: 'white', fontWeight: '600', fontSize: '15px' }}>CONTACT US</h6><p></p>
            <Accordiann />
          </div>

          <div className="col4">
            <div className="aroma-logo">
              <img src={mmm5} alt="aromo-logo" />
            </div>
            <div className="aroma-serv">
              <img className="serv-img" src={mmm1} alt="servimg" />
              <img className="serv-img" src={mmm2} alt="servimg" />
              <img className="serv-img" src={mmm3} alt="servimg" />
              <img className="serv-img" src={mmm4} alt="servimg" />
            </div>
            <div className="mas-title">Need a massage <br /> after your workout?</div>
            <a style={{ textDecoration: 'none' }} href='https://www.aromathai.com/'> <button className='xcx'>Book Now</button></a>

          </div>

        </div>
        <div className="footer-copy">
          <div className="copy-right"><div className="copy-title">©2022-23 CoreFit. All Rights Reserved.</div>
          </div></div>

      </div>

    </>
  )
}

export default Footer
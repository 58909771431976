import React from 'react'
import { useState } from 'react';
import './Navbar.css'
import LinkImg from '../Images/Link - Corefit Plus ⏵ Untitled-design-11-1.png';
import { Link } from 'react-router-dom';

const Navbar = () => {
    const scrollToTop = () => {
        window.scrollTo(0, 0)
    }

    const [isMobile, setisMobile] = useState(false);
    const openGoogleForm = () => {
        window.open('https://docs.google.com/forms/d/e/1FAIpQLSfN8fxItN5Urk_rTdkPv4yxDiFXCEHE_N915BOLlop3OhQBMA/viewform?usp=sf_link', '_blank');
      };

    return (
        <>
            <div className="navbar">
                <div className='navbar4'>
                    <div className='navbar2'>
                        <Link to="/">
                            <img src={LinkImg} alt='xxx' />
                        </Link>
                    </div>
                    <div className={isMobile ? 'navbar9' : 'navbar3'}
                        onClick={() => setisMobile(false)}>
                        <li><Link onClick={scrollToTop} className="no-underline" to="/">HOME</Link>
                        </li>
                        <li><Link onClick={scrollToTop} className="no-underline" to="/aboutus">ABOUT US</Link>
                        </li>
                        <li><Link onClick={scrollToTop} className="no-underline" to="/Classes">CLASSES</Link>
                        </li>
                        <li><Link onClick={scrollToTop} className="no-underline" to="/ourstudio">OUR STUDIOS</Link>
                        </li>
                        <li><Link onClick={scrollToTop} className="no-underline" to="/Faqs">FAQs</Link>
                        </li>
                        <li><Link onClick={scrollToTop} className="no-underline" to="/blog1">BLOG</Link>
                        </li>
                        <li><Link onClick={scrollToTop} className="no-underline" to="/contactus">CONTACT US</Link>
                        </li>
                        <button onClick={openGoogleForm}> Book a Trial Now</button>
                    </div>
                </div>
                <div className='navbar5'>
                    <div className='navbar6'>
                        <Link to="/">
                            <img src={LinkImg} alt='xxx' />
                        </Link>
                    </div>
                    <div className='navbar7'>
                        <button onClick={openGoogleForm} className='gaft'>Book a Trial Now</button>
                        <button className='hm'
                            onClick={() => setisMobile(!isMobile)}>
                            {isMobile ? <i className='fas fa-times'></i> :
                                <i className='fas fa-bars'></i>}
                        </button>
                    </div>
                </div>
            </div>
        </>
    )
}

export default Navbar
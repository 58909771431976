import './Whatsapp.css'
import React from 'react';

const Whatsapp = () => {
  const phoneNumber = '9625474665';
  const message = 'Hello';
  const whatsappLink = `https://wa.me/${phoneNumber}?text=${encodeURIComponent(message)}`;

  return (
    <div className='WhatsappContainer'>  
      <a href={whatsappLink} className='WhatsappLink' target="_blank" rel="noopener noreferrer">
        <i className="fa fa-whatsapp fa-3x" aria-hidden="true"></i>
      </a>
    </div>
  );
};

export default Whatsapp;

import React from 'react'
import Card from 'react-bootstrap/Card';
import './OurStudio.css'
import { Link } from 'react-router-dom'
import MeetUsAt from '../Components/MeetUsAt'
import FaqWidget from './FaqWidget'
import ourStudio from '../Images/Our Studios (2).webp'
import Whatsapp from '../Components/Whatsapp'
import cardimg2 from '../Images/Greater Kailash 1 Our Studio Page.webp'
import cardimg from '../Images/Noida Picture Gallery/2.jpg'
import cardimg3 from '../Images/Greenpark Our Studio Page.webp'
import Navbar from '../Components/Navbar';
import Headroom from 'react-headroom';
import { Helmet } from 'react-helmet';
import { useEffect } from 'react';


const OurStudio = () => {

  useEffect(() => {
    document.title = 'Our Studios | Pilates Studios in South Delhi and Noida';
  }, []);


  const handle = (id) => {
    const element = document.getElementById(id);
    if (element) {
      element.scrollIntoView({ behavior: 'smooth' });
    }
  }
  

  return (
    <>

      <Helmet>
        <title>Corefitplus Pilates Studio | Greater Kailash 1, Greenpark & Noida</title>
        <meta
          name="description"
          content="Are you looking for the best Pilates Workout Classes in South Delhi & Noida? Here is Corefit Plus Pilates Studio for Pilates classes. Book Your Trial Session With Our Expert Trainers"
        />
      </Helmet>



      <Whatsapp />
      <FaqWidget />

      <div className='Hero1'>
        <img src={ourStudio} alt='xxx' className='hero-image' />
        <Headroom>
          <div className='sticky-navbar'>
            <Navbar />
          </div>
        </Headroom>
      </div>
      <div class="overlayxy">

        <div className='contentt-container'>
          <p> <span className='writte1'>Our Studios</span>   </p>
        </div>        </div>

      <div className='ourstudio1'>
        <div className='ourstudio2'>
          <div>
            <Card className='werr'>
              <Card.Img src={cardimg2} alt='xxx' />
              <Card.Body>
                <Card.Title style={{ fontWeight: '600' }}>GREATER KAILASH-1</Card.Title>
                <Card.Text>
                  S- 15<br /> S Block<br /> Greater Kailash-1, New Delhi<br /> Delhi 110048


                </Card.Text>
                <Link onClick={() => handle("noida2")}>
                  <button >Visit</button>                </Link>              </Card.Body>
            </Card>

          </div>
          <div>

            <Card className='werr'>
              <Card.Img src={cardimg3} alt='xxx' />
              <Card.Body>
                <Card.Title style={{ fontWeight: '600' }}>GREEN PARK</Card.Title>
                <Card.Text>
                  K- 2<br />
                  Near Greenpark Market<br />
                  Green Park,<br />
                  New Delhi,
                  Delhi 110016<br />

                </Card.Text>
                <Link onClick={() => handle("noida3")} >

                  <button >Visit</button>                </Link>              </Card.Body>
            </Card>

          </div>
          <div>
            <Card className='werr'>
              <Card.Img src={cardimg} alt='xxx' />
              <Card.Body>
                <Card.Title style={{ fontWeight: '600' }}>NOIDA</Card.Title>
                <Card.Text>
                  301, Third floor <br />
                  Starling Edge, Village Hazipur, Sector 104<br />
                  Noida, Uttar Pradesh-201301.

                </Card.Text>
                <Link onClick={() => handle("noida1")}>

                  <button >Visit</button>                </Link>

              </Card.Body>
            </Card>


          </div>

        </div></div>
      <MeetUsAt />
    </>
  )
}

export default OurStudio
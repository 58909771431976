import React from 'react'
import './WorkOut.css'
import Whatsapp from '../Components/Whatsapp'
import FaqWidget from './FaqWidget'
import Navbar from '../Components/Navbar'
import MainImg from '../Images/Clasess.webp'
import { Helmet } from 'react-helmet'
import { useEffect } from 'react';
import { Link } from 'react-router-dom'

import Headroom from 'react-headroom';
const WorkOut = () => {
  useEffect(() => {
    document.title = 'Corefit Plus Pilates Studio | Pilates Class types ';
  }, []);

  const handle = (id) => {
    const element = document.getElementById(id);
    if (element) {
      element.scrollIntoView({ behavior: 'smooth' });
    }
  }
  const handleButtonClick = () => {
    // Check if the clicked element has the classname 'google-form-redirect'
      // Assuming the link you want to open is stored in a variable
      const googleFormLink = 'https://docs.google.com/forms/d/e/1FAIpQLSfN8fxItN5Urk_rTdkPv4yxDiFXCEHE_N915BOLlop3OhQBMA/viewform?usp=sf_link';
      
      // Open the link
      window.open(googleFormLink, '_blank');
    };
  

  return (
    <>
      <Helmet>
        <title>Workout types of Pilates | Corefit Plus Pilates Studio </title>
        <meta
          name="description"
          content="See the Perfect Pilates Exercise for Your Fitness Goals at CorefitPlus. Find the Perfect Class to Enhance Your Strength, Flexibility, and Well-Being. Join Us Today!"
        />
      </Helmet>




      <Whatsapp />
      <FaqWidget />

      <div className='Hero1'>
        <img src={MainImg} alt='xxx' className='hero-image' />
        <Headroom>
          <div className='sticky-navbar'>
            <Navbar />
          </div>
        </Headroom>
      </div>
      <div className='overlayxy'>

        <div className='contentt-container'>
          <p> <span className='writte1'>Classes</span>   </p>
        </div>
      </div>
      <div>
        <div className='toggle'>
          <span><Link className='link-pilates' onClick={() => handle("pilates")}>Pilates</Link>
          </span>  /  <span><Link className='link-pilates' onClick={() => handle("yoga")}>Yoga</Link></span>
        </div>
        <hr className='mnbv' />



        <section id='pilates'>
          <div className='wo'>
            <div className='Wo1'>
              <div className='wo22'><p>PILATES</p>
                <hr className='mnbv' /></div>
              <div className='wo33'><p><div>CLASSES FOR EVERYBODY</div>
                Explore our range of Pilates class types designed to cater to your unique needs and preferences. Whether you're a beginner looking to build a strong foundation or an advanced practitioner seeking a challenging workout, we have the ideal classes for you.
                <p style={{ fontSize: '18px' }}>
                  We offer Group Sessions, Private Sessions, Private Duo at our Studios.
                </p></p>

              </div>
            </div>
            <div className='Wo1'>

              <div className='wo2'><p>FOUNDATION CLASS</p><hr /></div>
              <div className='wo3'><p><div>LEARN THE BASICS OF PILATES</div><br />


                This is a wonderful class for beginners to briefly understand Pilates principle, postures and breathing technique while focusing on ideal form and alignment. It can be taken privately or in small group sessions where the instructor can give personalized attention on the techniques and exercise forms to build great foundation for your physique type. We recommend at least 2-3 sessions of this foundation class to start with so that you can master your Pilates Fundamentals and get the most out of your following workouts as you advance. The duration of this class is 55 minutes
                <br /><br />
                Intensity: OPEN TO ALL LEVELS<br />
                <button onClick={()=> handleButtonClick()}>Book a Trial Now</button>

              </p></div>
            </div>
            <div className='Wo1'>

              <div className='wo2'><p>SIGNATURE FULL BODY REFORMER</p><hr /></div>
              <div className='wo3'><p><div> STRENGTHEN & LENGTHEN</div><br />


                Designed for all, this strengthen and lengthen workout session will help you to build stamina, flexibility, coordination, and strong balance. These sessions are done using Reformer machine, stability chair, TRX, magic rings and other Pilates props for you to experience variations in fun and challenging ways. Experience the benefits of Pilates with a twist.

                <br /><br />
                Intensity: OPEN TO ALL LEVELS<br />
                <button onClick={()=> handleButtonClick()}>Book a Trial Now</button>

              </p></div>
            </div>
            <div className='Wo1'>

              <div className='wo2'><p>CHAIR BALANCE + REFORMER</p><hr /></div>
              <div className='wo3'><p><div>FOCUS ON CORE AND BALANCE</div><br />


                Great core stability workout routines performed on Wunda chairs to achieve maximum core strength, muscular endurance and balance. Added choreography with this challenging and complex Pilates' apparatus promises a high intensity and heart pumping workout. This workout requires a strong sense of mind-body connection and breathing technique to help gain maximum results!                   <br /><br />
                Intensity: OPEN TO ALL LEVELS<br />
                <button onClick={()=> handleButtonClick()}>Book a Trial Now</button>

              </p></div>
            </div>
            <div className='Wo1'>

              <div className='wo2'><p>CARDIO INTERVAL PILATES</p><hr /></div>
              <div className='wo3'><p><div>HEART-PUMPING INTERVAL TRAINING</div><br />


                A high-intensity, calorie-blasting cardio workout with a combination of Jumpboard Reformer based strength training is what makes up this energetic class. This high­impact, interval training workout session is dynamic and fast-paced. A solid Pilates foundation is required to join this class, minimum 5 to 6 prior pi late sessions would be ideal.


                <br /><br />
                Intensity: INTERMEDIATE TO ADVANCE LEVEL <br />
                <button onClick={()=> handleButtonClick()}>Book a Trial Now</button>

              </p></div>
            </div>
            <div className='Wo1'>

              <div className='wo2'><p>TRX FUNCTIONAL & REFORMER</p><hr /></div>
              <div className='wo3'><p><div>BUILD STRENGTH & MUSCLE
              </div><br />

                A Reformer based Pilates workout is combined with the incredibly effective TRX® suspension training system. Efficient and precise routines are performed to enhance strength, coordination, mobility and balance. An excellent choice for sports lovers who want to challenge their limits and enjoy muscle building exercises in quick transitions.
                <br /><br />
                Intensity: INTERMEDIATE LEVEL <br />
                <button onClick={()=> handleButtonClick()}>Book a Trial Now</button>

              </p></div>
            </div>
            <div className='Wo1'>

              <div className='wo2'><p>BURNOUT</p><hr /></div>
              <div className='wo3'><p><div>LETS PROVE OUR ENDURANCE</div><br />


                A high-intensity endurance class aimed to burn maximum body fat working on bigger muscle groups. Expect squats, planks, challenging arm work, and compound exercises throughout this routine to keep your heart racing. The best workout to get toned and sculpted with long & lean muscles.
                <br /><br />
                Intensity: INTERMEDIATE LEVEL <br />
                <button onClick={()=> handleButtonClick()}>Book a Trial Now</button>

              </p></div>
            </div>
            <div className='Wo1'>

              <div className='wo2'><p>ENERGETIC CARDIO PILATES</p><hr /></div>
              <div className='wo3'><p><div>SWEAT & TONE</div><br />


                A fun workout routine which requires quick transitions between the jumpboard and reformer exercises. This class incorporates multiple fitness modalities to take your practice to a new level. Be ready to sweat and pump up your heart rate in this super engaging full-body Pilates workout. This high-energy, low-impact class is the perfect solution for all your cardio needs and will also help to increase your bone density.

                <br /><br />
                Intensity: INTERMEDIATE TO ADVANCED LEVEL<br />
                <button onClick={()=> handleButtonClick()}>Book a Trial Now</button>

              </p></div>
            </div>
            <div className='Wo1'>

              <div className='wo2'><p>SIGNATURE PILATES FOR MEN</p><hr /></div>
              <div className='wo3'><p><div>
                PILATES WAS CREATED BY MAN FOR MEN
              </div><br />
                This Pilates reformer class is specially designed for men who are ready to challenge their strength, core, control and balance. This is a total body workout where you perform complex Pilates moves at a vigorous pace. Prepare to burn, sweat, repeat and be ready for a transformation of the body while you achieve real deep core strength and a super toned look.              <br /><br />
                Intensity: OPEN TO ALL LEVELS  <br />
                <button onClick={()=> handleButtonClick()}>Book a Trial Now</button>
              </p></div>
            </div>
            <div className='Wo1'>

              <div className='wo2'><p>BEGINNER'S BLISS</p><hr /></div>
              <div className='wo3'><p><div>RIGHT WAY TO START PILATES
              </div><br />

                This class will allow you to connect with muscles you thought you never had. It is designed to challenge you to rediscover your body, your posture,and find your balance and stability,as you strengthen your body in new ways. This class will move in a gentle pace, and you will be encouraged to focus on your breath and proper form. The challenge in this class is to move with control and precision while engaging your core muscles; expect to be challenged by the basics! No previous experience with pilates is necessary. We do recommend taking a couple of these classes before progressing. You will build confidence, feel your muscles fire and have fun.              <br /><br />
                Intensity: OPEN TO ALL LEVELS<br />
                <button onClick={()=> handleButtonClick()}>Book a Trial Now</button>

              </p></div>
            </div>
            <div className='Wo1'>

              <div className='wo2'><p>BOSU BALANCE AND REFORMER</p><hr /></div>
              <div className='wo3'><p><div>            PUSHING BOUNDARIES AND TESTING STABILITY
              </div><br />

                Strengthening the Stabilizer Muscles. Try this class if you want an exercise routine to be more challenging and fun. Bosu trainers are versatile enough to use for stretching, balance-training, rehabilitat­-ion and even strength-training and plyometric training. Bosu trainer in combination with Functional training, TRX training and Free weights force your core to work constantly throughout the entire motion. This is an excellent way to make easy exercises more challenging.
                <br /><br />
                Intensity: INTERMEDIATE LEVEL<br />
                <button onClick={()=> handleButtonClick()}>Book a Trial Now</button>

              </p></div>
            </div>
            <div className='Wo1'>

              <div className='wo2'><p>STRONGER AT 50</p><hr /></div>
              <div className='wo3'><p><div>RENEW & STRENGTHEN YOUR MUSCULAR SYSTEM
              </div><br />

                This Pilates routine is aimed at 50's and over age group to target core strength, balance, flexibility and postural correction. It is a small group session to ensure personalized attention and detailed coaching including helping those with challenging back aches and other aches to build supporting muscles around their pain areas. Gradually restore your age and strength and revive your flexibility and alignment session after session.               <br /><br />
                Intensity: OPEN TO ALL LEVELS <br />
                <button onClick={()=> handleButtonClick()}>Book a Trial Now</button>

              </p></div>
            </div>

            <div className='Wo1'>

              <div className='wo2'>
                <p>UPPER BODY BLAST</p><hr /></div>
              <div className='wo3'><p><div>SLEEK AND SCULPTED
              </div><br />

                This reformer workout targets the entire upper body with a mix of fun and challenging exercises. This class focuses on building strength in the entire upper body including the arms, shoulders, chest, triceps, and more.Different props are used in various ways to encourage proper form,engagement, and add a great spin to any movement.

                <br /><br />
                Intensity: OPEN TO ALL LEVELS    <br />
                <button onClick={()=> handleButtonClick()}>Book a Trial Now</button>
              </p></div>
            </div>
            <div className='Wo1'>

              <div className='wo2'><p>CORETASTIC CRUNCH</p><hr /></div>
              <div className='wo3'><p> SHAKE THE ABS</p>

                <div className='wo0'>
                  This reformer workout targets the deep muscles of the abdomen back and pelvis. This class focuses on maintaining proper form and alignment using your breath to support movements, and engaging your deep core muscles, including your obliques and transverse abs. Building strength in the core, from top to bottom + front to back, will provide you with better posture, strong breathing pattern, and an increase in balance+ stability. Plus, everyone loves working
                  their abs. Right?

                </div><br />
                Intensity: INTERMEDIATE LEVEL<br />
                <button onClick={()=> handleButtonClick()}>Book a Trial Now</button>

              </div>
            </div>
            <div className='Wo1'>

              <div className='wo2'><p>MAT PILATES</p><hr /></div>
              <div className='wo3'><p>DYNAMIC MOVEMENTS AND DEEP BREATHWORK
                <br />
                <div className='wo0'><p>
                  A full-body workout that harnesses the power of controlled movements and breath synchronization. This class aims to improve flexibility, build strength, and develop control and endurance in the entire body. It places a special emphasis on alignment, breathing, and enhancing core stability and balance. Good for beginners and advanced practitioners.
                </p></div><br />
                Intensity: OPEN TO ALL LEVELS<br />
                <button onClick={()=> handleButtonClick()}>Book a Trial Now</button>

              </p></div>
            </div>
          </div>

        </section>


        <section id='yoga'>
          <div className='we'>
            <div className='part2'>
              <div className='Wo1'>
                <div className='uo22'><p>Yoga</p>
                  <hr className='mnbv' /></div>
                <div className='wo33'>
                  <p>A Better Life For All</p>
                  <p className='wo0'>This 21st century Yoga is built on western scientific foundations combined with the wisdom of Ayurveda. In every single session, experience better health and satisfaction as you enjoy the benefits of a youthful, well-toned body to be able to live life to your fullest.
                  </p>
                  <p style={{ fontSize: '19px' }}>
                    Yoga classes are available only at Noida Studio.
                  </p>
                </div>
              </div>
              <div className='Wo1'>
                <div className='uo2'><p>ENERGY & BALANCE</p><hr /></div>
                <div className='wo3'><p>SUN VINYASA
                  <br />
                  <div className='wo0'><p>Awakening and balancing your solar power, this Solar series is a great way to be fit and healthy and is suitable for athletes, students, working professionals, and seniors alike! This class benefits all systems of the body i.e. cardiovascular, muscular, skeletal and nervous systems.</p>  </div><br />
                  Intensity: OPEN TO ALL LEVELS<br />
                  <button onClick={()=> handleButtonClick()}>Book a Trial Now</button>
                </p></div>
              </div>
              <div className='Wo1'>
                <div className='uo2'><p>CORE STRENGTH</p><hr /></div>
                <div className='wo3'><p>YOGA CORE
                  <br />
                  <div className='wo0'><p>These postures help reduce back pain, strengthen the core muscles that support the back, hips, and stomach. Having strong abdominal muscles and back muscles is the key to prevent back pain. Strong core helps advancing and deepening in various yoga postures and other spiritual practices.</p>  </div><br />
                  Intensity: OPEN TO ALL LEVELS<br />
                  <button onClick={()=> handleButtonClick()}>Book a Trial Now</button>
                </p></div>
              </div>
              <div className='Wo1'>
                <div className='uo2'><p>ASANA BASED CARDIO</p><hr /></div>
                <div className='wo3'><p>CARDIO YOGA
                  <br />
                  <div className='wo0'><p>Stop running or cycling and try our cardio yoga that delivers maximum benefits from burning fats for 24 to 48 hrs after you complete your exercise along with toning and firming your body that you usually can’t get from running. There is no better exercise that incorporates complete body training that works your core, upper body and lower body strength versus just working your legs alone. Strengthen your heart, burn fats and boost metabolism in this amazing class.</p>  </div><br />
                  Intensity: INTERMEDIATE LEVEL <br />
                  <button onClick={()=> handleButtonClick()}>Book a Trial Now</button>
                </p></div>
              </div>
              <div className='Wo1'>
                <div className='uo2'><p>REJUVENATE</p><hr /></div>
                <div className='wo3'><p>ASANA PRANA
                  <br />
                  <div className='wo0'><p>Use of specific postures and breathing techniques to help relieve stress and tension instantly. This class helps align the body, increase blood circulation, improve the endocrine activity and correct body metabolism along with physiological functions.
                  </p>  </div><br />
                  Intensity: OPEN TO ALL LEVELS<br />
                  <button onClick={()=> handleButtonClick()}>Book a Trial Now</button>
                </p></div>
              </div>
              <div className='Wo1'>
                <div className='uo2'><p>DETOX & TONE</p><hr /></div>
                <div className='wo3'><p>HOT DHARA
                  <br />
                  <div className='wo0'><p>Performed in Hot infra-red light and not traditional heaters. A flowing series of 31 postures helps burn fats fast along with making your body lean and toned. It helps relieve health problems like migraine, back pain, neck pain, numbness, respiratory issues, menstrual issues and sleeping disorders.</p>  </div><br />
                  Intensity: INTERMEDIATE LEVEL<br />
                  <button onClick={()=> handleButtonClick()}>Book a Trial Now</button>
                </p></div>
              </div>
              <div className='Wo1'>
                <div className='uo2'><p>POWER FLOW</p><hr /></div>
                <div className='wo3'><p>YOGA SHAKTI
                  <br />
                  <div className='wo0'><p>This class covers all aspects of classical Yoga practice in a systematic way. Specific Pranayama (breathing), Asana (postures), Dharana (concentration), Bandha (locks) and Nidra (blissful sleep) awaken dormant powers. This systematic approach will give you the foundation to reach greater heights as you achieve better health both physically and mentally.
                  </p>  </div><br />
                  Intensity: INTERMEDIATE LEVEL
                  <br />
                  <button onClick={()=> handleButtonClick()}>Book a Trial Now</button>
                </p></div>
              </div>
              <div className='Wo1'>
                <div className='uo2'><p>REVERSE AGING</p><hr /></div>
                <div className='wo3'><p>ANTI-AGING YOGA
                  <br />
                  <div className='wo0'><p>This class raises your body temperature, compresses and decompresses the body tissues to eliminate the toxins. The poses help with lymphatic drainage, constipation relief and removal of energy blocks. All the stretching, twisting and inversions will help revitalize and reverse aging.
                  </p>  </div><br />
                  Intensity: OPEN TO ALL LEVELS<br />
                  <button onClick={()=> handleButtonClick()}>Book a Trial Now</button>
                </p></div>
              </div>
              <div className='Wo1'>

                <div className='uo2'><p>YOUTHFUL & PAIN</p><hr /></div>
                <div className='wo3'><p>YOGA STRETCH
                  <br />
                  <div className='wo0'><p>It helps de-stress, relieve pain and stiffness, improves elasticity of skin and muscles, improves range of motion, releases blocked energy and boosts circulation. In other words, it postpones aging while making you feel light and refreshed in each session
                    .</p>  </div><br />
                  Intensity: INTERMEDIATE LEVEL<br />
                  <button onClick={()=> handleButtonClick()}>Book a Trial Now</button>
                </p></div>
              </div>
              <div className='Wo1'>

                <div className='uo2'><p>HEAL BODY & MIND</p><hr /></div>
                <div className='wo3'><p>YOGA CHIKITSA
                  <br />
                  <div className='wo0'><p>This is a therapeutic aspect of yoga, especially for those with sports injuries and any health problems like stress, high blood pressure, back and neck pain, asthma, gastric issues, constipation and sleep disorders.</p>  </div><br />
                  Intensity: OPEN TO ALL LEVELS<br />
                  <button onClick={()=> handleButtonClick()}>Book a Trial Now</button>
                </p></div>
              </div>
              <div className='Wo1'>

                <div className='uo2'><p>BODY & MIND GROWTH</p><hr /></div>
                <div className='wo3'><p>YOGA FOR TWEENS
                  <br />
                  <div className='wo0'><p>Playful poses for the youth that work well on the body, mind, and breath. Yoga helps youngsters with their rapid growth, making the teen’s body strong and flexible, improves clarity and focus, empowers their mind, emotions and energy in a positive way.
                  </p>  </div><br />
                  Intensity: OPEN TO ALL LEVELS<br />
                  <button onClick={()=> handleButtonClick()}>Book a Trial Now</button>
                </p></div>
              </div>
            </div>
          </div>
        </section>
      </div>
    </>
  )
}

export default WorkOut
import React from 'react'
import './TurnYC.css'
import imgtyc from '../Images/training1.jpg'
import { useState } from 'react'

const TurnYC = () => {


  const [formData, setFormData] = useState({
    fname: '',
    lname:' ',
    email: ' ',
    phone:' ',
    message: ' '
  });

  const handleChange = (e) => {
    const { name, value } = e.target;
    setFormData({
      ...formData,
      [name]: value
    });
  }
  const handleSubmit = (e) => {
    e.preventDefault();
    
    const { fname, lname, email, phone, message } = formData;
    const mailtoLink = `mailto:abc@gmail.com?subject=Form Submission&body=Name: ${fname}%20${lname}%0AEmail: ${email}%0APhone: ${phone}%0AMessage: ${message}`;
    window.location.href = mailtoLink;

  }
 


  return (
    <>
      <div className='tyc1'>
        <div className='tyc2'>
          <p className='tyc3'> Turn your passion into your career!</p>
          <p className='tyc4'> Specialize your fitness knowledge by becoming a certified Pilates Instructor. Learn about Reformer and mat Pilates exercises, routine design, Pilates fusion exercises, functional training and more.
          </p>
          <p className='tyc4'> Corefit Plus Certification Series (Beginner to Intermediate Level) offer you comprehensive Pilates training on large Equipment. This Certification Series covers training on the Mat Pilates and Reformer
            Pilates both. You’ll be taught by experienced Instructor Trainers who can mentor you on your education path.
          </p>
        </div>
        <div className='tyc5'>
          <p className='tyc6'>What you will learn</p>
          <div className='tyc7'>
            <div className='tyc8'>
              <div className='tyc10'>
                <img src={imgtyc} alt='xxx' />
              </div>
            </div>
            <div className='tyc9'>
              <ul>
                <li>Introduction of Pilates</li>
                <li>Contemporary Pilates Principles</li>
                <li> Anatomy</li>
                <li>Reformer Repertoire</li>
                <li> Course Manual with Illustrations</li>
                <li> Program Designing</li>
                <li> Theory and Practical Assessments</li>
                <li> Course Completion Certificates</li>
              </ul>
            </div>
          </div>
        </div>

      </div>
      <div className='cun2'>
          <div className='tycc12'>
            <h4 style={{ fontFamily: "'Demibolds',sans-serif", color: 'black' }}>CONTACT FORM <hr className='cu9' />
            </h4>
            <div className='tycc13'>
              <form onSubmit={handleSubmit}>
                <div className='tycc14'>
                  <div>
                    <input onChange={handleChange} value={FormData.fname} type="text" name="fname" placeholder='First Name*' />
                    <input onChange={handleChange} value={FormData.lname} type="text" name='lname' placeholder='Last Name*' />
                  </div>
                  <div>
                    <input onChange={handleChange} value={FormData.email} type="text" name='email' placeholder='Email*' />
                    <input onChange={handleChange} value={FormData.phone} type="number" name='phone' placeholder='Phone*' />
                  </div>
                </div>
                <textarea onChange={handleChange} value={FormData.message} type="text" name='message' rows="7" placeholder='Type your comment*'></textarea><br /><br />
                <button type='submit'>Submit</button>
              </form>
            </div>
          </div>
        </div>
    </>
  )
}

export default TurnYC
import React from 'react'
import './Main3.css'
import Scroller from './Scroller'
import { Link } from 'react-router-dom'
const Main3 = () => {
    const scrollToTop = () => {
        window.scrollTo(0, 0)
    } 
      const openGoogleForm = () => {
        window.open('https://docs.google.com/forms/d/e/1FAIpQLSfN8fxItN5Urk_rTdkPv4yxDiFXCEHE_N915BOLlop3OhQBMA/viewform?usp=sf_link', '_blank');
      };
    return (
        <>
            <div className='mm'>
                <div>
                    <Scroller />
                </div>
            </div>
            <div className='un'>
                <div className='image-containerx'>
                    <div className='text-box1'>
                        <p>Meet us at <br />
                             Greater Kailash 1 | Green Park | Noida </p>
                        <p className='pop' ><span style={{ color: ' #E6D3B3' }}>Book</span> Your Class or <span style={{ color: ' #E6D3B3' }}>Ask </span> Any Question.</p>
                        <div className='buttons'>
                            <button onClick={openGoogleForm}>BOOK A TRIAL</button>
                            <Link onClick={scrollToTop} to='/contactus'><button>CONTACT US</button></Link>
                        </div>
                    </div>
                </div>
            </div>




        </>
    )
}

export default Main3
import React, { useState } from 'react';
import './ImageGrid.css'; // Import your CSS file
import NoidaImg1 from '../Images/Noida Picture Gallery/1.jpg'
import NoidaImg2 from '../Images/Noida Picture Gallery/1.png'
import NoidaImg3 from '../Images/Noida Picture Gallery/2.jpg'
import NoidaImg4 from '../Images/Noida Picture Gallery/3.jpg'
import Nimg6 from '../Images/Noida Gallery/Noida Gallery (2).webp'
import Nimg5 from '../Images/Noida Picture Gallery/4.jpg'
import Nimg7 from '../Images/Noida Picture Gallery/5.jpg'
import Nimg8 from '../Images/Noida Picture Gallery/6.jpg'
import Nimg9 from '../Images/Noida Gallery/Noida Gallery.webp'
import Nimg10 from '../Images/123mm.png'

const ImageGrid = () => {
    const [selectedImage, setSelectedImage] = useState(null);

    const images = [Nimg10, NoidaImg2, NoidaImg3, NoidaImg4, Nimg5,
        Nimg6, Nimg7, Nimg8, Nimg9, NoidaImg1
    ];

    const handleImageClick = (image) => {
        setSelectedImage(image);
    }

    const handleCloseCarousel = () => {
        setSelectedImage(null);
    }

    const handleNextImage = () => {
        const currentIndex = images.indexOf(selectedImage);
        if (currentIndex < images.length - 1) {
            setSelectedImage(images[currentIndex + 1]);
        } else {
            setSelectedImage(images[0]);
        }
    }
    const handlePrevImage = () => {
        const currentIndex = images.indexOf(selectedImage);
        if (currentIndex > 0) {
            setSelectedImage(images[currentIndex - 1]);
        }
    }
    return (
        <div className="ui1">
            <div className="ui2">
                <div className="ui3">
                    {images.slice(0, 5).map((image, index) => (
                        <div key={index} className="image-container">
                            <img
                                src={image}
                                alt={`Image ${index + 1}`}
                                onClick={() => handleImageClick(image)}
                            />
                        </div>
                    ))}
                </div>
                <div className="ui3">
                    {images.slice(5).map((image, index) => (
                        <div key={index} className="image-container">
                            <img
                                src={image}
                                alt={`Image ${index + 6}`}
                                onClick={() => handleImageClick(image)}
                            />
                        </div>
                    ))}
                </div>
            </div>

            {selectedImage && (
                <div className='overl'>
                    <div className="carousel">
                        <button className="close-button" onClick={handleCloseCarousel}><i className='fas fa-times'></i></button>
                        <button onClick={handlePrevImage}><i class="fa-solid fa-chevron-left"></i></button>
                        <img src={selectedImage} alt="Selected" />
                        <button onClick={handleNextImage}><i class="fa-solid fa-chevron-right"></i></button>
                    </div>                </div>


            )}
        </div>
    );
}
export default ImageGrid;


import React, { useState } from 'react';
import './Accodiann.css'; // You can create a separate CSS file for styling
import { Link } from 'react-router-dom';

import PhoneImg from '../Images/ic_phone.svg'
import EmailImg from '../Images/ic_mail.svg'
function Accordion({ title, content, isOpen, onToggle }) {





    return (
        <div className="accordio">
            <div className="accordion-header" style={{ padding: '5px 5px', height: "45px" }} onClick={onToggle}>
                <p className={`accordion-icon ${isOpen ? 'open' : ''}`}>{isOpen ? '-' : '+'}</p>
                <p>{title}</p>
            </div>
            {isOpen && <div className="accordion-content">{content}</div>}
        </div>
    );
}

function App() {


    const [accordions, setAccordions] = useState([
        {
            title: 'GREATER KAILASH-1', content: <div className='mkl'>

                <div className='address'>
                    <p className='fc66'><img src={PhoneImg} alt='xxx' />+91 99102 24464,<br />+91 11 4752 9468</p>
                    <p className='fc77'><img src={EmailImg} alt='xxx' />gkone@corefitplus.com</p>
                </div>
            </div>, open: true
        },
       
        {
            title:'GREENPARK', content: <div className='mkl'>
                <div className='address'>
                    <p className='fc66'><img src={PhoneImg} alt='xxx' /> +91 99710 04464,<br />+91 11 3504 9625
                    </p>
                    <p className='fc77'><img src={EmailImg} alt='xxx' />greenpark@corefitplus.com</p>
                </div>
            </div>, open: false
        },
        {
            title:  'NOIDA', content: <div className='mkl'>
                <div className='address'>
                    <p className='fc66'><img src={PhoneImg} alt='xxx' />+91 85952 68566,<br />+91 96677 44326</p>
                    <p className='fc77'><img src={EmailImg} alt='xxx' />starling@corefitplus.com</p>

                </div>
            </div>, open: false
        }
    ]);

    const toggleAccordion = (index) => {
        const updatedAccordions = accordions.map((accordion, i) => ({
            ...accordion,
            open: i === index ? !accordion.open : false
        }));
        setAccordions(updatedAccordions);
    };

    return (
        <>
            {accordions.map((accordion, index) => (
                <Accordion
                    key={index}
                    title={accordion.title}
                    content={accordion.content}
                    isOpen={accordion.open}
                    onToggle={() => toggleAccordion(index)}
                />
            ))}
        </>
    );
}

export default App;

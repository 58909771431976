import React from 'react'
import './Faq.css'
import Accordion from 'react-bootstrap/Accordion';
import Whatsapp from '../Components/Whatsapp'
import Navbar from '../Components/Navbar'
import MainImg from '../Images/FAQ.webp';
import { Helmet } from 'react-helmet';
import { useEffect } from 'react';



import Headroom from 'react-headroom';
const Faqs = () => {


  useEffect(() => {
    document.title = 'Corefit Plus Pilates Studio | Frequently Asked Questions';
  }, []);

  return (
    <>

      <Helmet>
        <title>Corefit Plus Pilates Studio | FAQ’s</title>
        <meta
          name="description"
          content="Your Pilates Studio FAQs Answered - Get Informed, Get Inspired. Explore CorefitPlus' Frequently Asked Questions to Enhance Your Wellness Journey."
        />
      </Helmet>



      <Whatsapp />
      <div className='Hero1'>
        <img src={MainImg} alt='xxx' className='hero-image' />
        <Headroom>
          <div className='sticky-navbar'>
            <Navbar />
          </div>
        </Headroom>
      </div>
      <div className='overlayxy' >

        <div className='contentt-container'>
          <p> <span style={{ letterSpacing: '3px' }} className='writte1'>FAQ's</span>   </p>
        </div>      </div>

      <div className='container'>
        <div className='faq1'>
          <h1> FAQ's
          </h1>
          <p>Frequently Asked Questions</p>

          <br />
          <Accordion className='faqaccor'>
            <Accordion.Item eventKey="0">
              <Accordion.Header><h3>1. What is Pilates?</h3></Accordion.Header>
              <Accordion.Body>
                <p>
                  Pilates is a full-body exercise system that uses a series of machines and exercises. It works the
                  entire body, both the right and left sides, in unison. It focuses primarily on what Joseph Pilates
                  called the "powerhouse" or the group of muscles that begins two inches below your navel, goes
                  two inches above your navel and then wraps completely around your front and your back-kind
                  of like a corset. It also includes your buttocks. With Pilates, no matter what exercise you are
                  doing, you are focusing on this powerhouse area.
                </p> </Accordion.Body>
            </Accordion.Item>

            <Accordion.Item eventKey="1">
              <Accordion.Header><h3>2. Why should I do Pilates?</h3></Accordion.Header>
              <Accordion.Body>
                <p>
                  Pilates conditions the smaller muscles throughout the body so you can improve your balance,
                  mobility, and stability while preventing injuries. It is a full-body, low-impact workout that works
                  on core strength without overloading or stressing joints.                </p> </Accordion.Body>
            </Accordion.Item>
            <Accordion.Item eventKey="2">
              <Accordion.Header> <h3>3. What equipment is used in Pilates?</h3></Accordion.Header>
              <Accordion.Body>
                <p>
                  When you walk into a Pilates studio, you will notice a variety of equipment’s. The Reformer is
                  the main apparatus that you will be working on which uses your own body weight as well as
                  springs and straps to increase or decrease the challenge. Your instructor will help you get used
                  to the new motions and be prepared to feel muscles that you’ve never felt before!!
                  The Wunda Chair, Bosu, Springboard, Ladder Barrel, Spine Corrector, Core Align, Classic Cadillac
                  and TRX are additional pieces of equipment used in class. They all add variety and constant
                  challenges to each class session, so you are guaranteed to never be bored!
                </p> </Accordion.Body>
            </Accordion.Item>

            <Accordion.Item eventKey="3">
              <Accordion.Header><h3>4. Who can do Pilates?</h3></Accordion.Header>
              <Accordion.Body>
                <p>
                  Almost anyone can do Pilates. Our clients include people with a variety of orthopedic and
                  medical issues as well as professional athletes. Our client’s range in age from 13 to 90 years old.
                  Pilates may also be beneficial for a number of medical conditions including Scoliosis,
                  Fibromyalgia, Parkinson’s, MS, pelvic floor atrophy, and back pain.                  </p>  </Accordion.Body>

            </Accordion.Item>

            <Accordion.Item eventKey="6">
              <Accordion.Header><h3>5. How can beginners get the most benefit from Pilates?</h3></Accordion.Header>
              <Accordion.Body>
                <p>
                  Be consistent, especially in the beginning. Don't just try it once. Give it a few shots and do it in
                  succession. Make it your reward, your break from a hectic day. Also, listen to your body and
                  really concentrate — it makes for a better workout.
                  Someone once told me that you need to "arrive" for Pilates, meaning you must be there
                  mentally as well as physically. To get the most out of Pilates, you must be present. Your body
                  and mind will thank you.                   </p></Accordion.Body>
            </Accordion.Item>
            <Accordion.Item eventKey="7">
              <Accordion.Header><h3>6. Does Pilates help weight loss?</h3></Accordion.Header>
              <Accordion.Body>
                <p>
                  Yes, Pilates can contribute to weight loss when combined with a good diet, which plays a crucial
                  role in weight loss. Pilates, if done consistently, can complement these dietary efforts by
                  enhancing muscle definition and boosting metabolism, which can aid in the overall weight loss
                  process.                </p>   </Accordion.Body>
            </Accordion.Item>
            <Accordion.Item eventKey="8">
              <Accordion.Header><h3>7. How is Pilates different from other forms of Exercise?
              </h3></Accordion.Header>
              <Accordion.Body>
                <p>
                  Pilates is different from most exercises out there because it's non-impact and safe, and it really
                  works on using the body as a whole. You're either lying on your back, on your side or kneeling
                  on the floor where it's safe. When you move the body, you're trying to move it from the
                  powerhouse, using your abdominal wall to protect your back. You're also working the body very
                  evenly and symmetrically, making sure one side is not working harder than the other.
                </p>   </Accordion.Body>
            </Accordion.Item>



            <Accordion.Item eventKey="10">
              <Accordion.Header><h3>8. What would you say is the key difference between Yoga and Pilates?

              </h3></Accordion.Header>
              <Accordion.Body>
                <p>
                  There is a mind-body connection and a very similar fluidity in both. But one difference is that
                  there is a whole line of equipment in Pilates that doesn't exist in yoga, so it provides a different
                  angle: You're doing exercises with the assistance and resistance of springs and pulleys. The
                  springs may assist you or they may make an exercise more difficult, depending on the exercise.                  </p>
              </Accordion.Body>
            </Accordion.Item>

            <Accordion.Item eventKey="11">
              <Accordion.Header><h3>9. I have back problems. Is Pilates safe for me to do?
              </h3></Accordion.Header>
              <Accordion.Body>
                <p>
                  It’s a legitimate question. If you have back issues or pain, you’re not the only one wondering if
                  you can safely do Pilates. Many physiotherapists recommend that their clients with back
                  problems take up Pilates, as it will help to strengthen your core muscles and potentially
                  improve spine mobility.
                  Pilates is a wonderful exercise option for people who have a history of back problems but who
                  are now free from pain and ready to exercise. Anyone with a back problem that is Postural can
                  really reap the benefits of Pilates. Everyone must be cleared by a medical practitioner and
                  always make your instructor aware of your medical history. A qualified instructor can also show
                  you how to modify exercises, ensuring you move safely and mindfully.
                </p>
              </Accordion.Body>
            </Accordion.Item>

            <Accordion.Item eventKey="12">
              <Accordion.Header><h3>10. What if I have an injury. Can I do Pilates?
              </h3></Accordion.Header>
              <Accordion.Body>
                <p>
                  Pilates based exercises are commonly used at physical therapy offices, chiropractors, and
                  training centers. What makes Pilates so effective in the treatment of an injury is that it
                  addresses body imbalances the cause the pain. Pilates is low impact and can be modified to
                  address your needs. Our instructors use a safe and cautious approach and have worked with
                  clients with all different challenges, including but not limited to - scoliosis, back injuries,
                  arthritis, knee injuries, cancer and shoulder injuries. The method helps to correct posture,
                  strengthen the core, increase flexibility, and increase your body awareness. When you have a
                  strong center with flexible joints, you are able to align and support your body and spine
                  correctly, resulting in less strain and pain.<br />
                  *Always check with your physician before starting a Pilates program.
                </p>
              </Accordion.Body>
            </Accordion.Item>

            <Accordion.Item eventKey="13">
              <Accordion.Header><h3>11. Do I have to be in shape to do Pilates?

              </h3></Accordion.Header>
              <Accordion.Body>
                <p>
                  No! Absolutely not- we welcome all fitness levels and abilities! One amazing thing about Pilates
                  is that it is adaptable and catered toward an individual's place in life, whether you're a pro with
                  years under your belt, or just starting off on your Pilates journey.
                </p>
              </Accordion.Body>
            </Accordion.Item>



            <Accordion.Item eventKey="14">
              <Accordion.Header><h3>12. How many times a week should I do Pilates?


              </h3></Accordion.Header>
              <Accordion.Body>
                <p>
                  Generally, this depends on your goals.
                  Ideally, we recommend practicing 3 times a week- but as we all know, sometimes life gets in
                  the way! There are always more considerations, but you'll see results faster if you take more
                  classes per week. You will find the routine that works for your life and goals.     </p>
              </Accordion.Body>
            </Accordion.Item>



            <Accordion.Item eventKey="15">
              <Accordion.Header><h3>13. What should I wear to my Pilates class?

              </h3></Accordion.Header>
              <Accordion.Body>
                <p>
                  Comfortable, form fitting exercise clothes are best. Dress comfortably so that your movement is
                  free but try not to wear clothing that is too loose fitting as you will be performing exercises that
                  could cause a loose shirt to drift up the torso or loose shorts to fall open at the hip. Oversized
                  clothes make it difficult for the instructor to observe your body during the workout. Socks are a
                  must.<br />
                  **Please no clothing with zippers- they can tear/rip the upholstery**    </p>
              </Accordion.Body>
            </Accordion.Item>


            <Accordion.Item eventKey="16">
              <Accordion.Header><h3>14. Do you work with teens?
              </h3></Accordion.Header>
              <Accordion.Body>
                <p>
                  Yes, we do work with teens. Teenagers 16 and up are welcome to join group classes. Ages 13-15
                  are welcome to join in either a private or semi-private session, with the potential to join group
                  classes (this can be determined on a case-by-case basis). At the moment, we do not take clients
                  aged 12 and under.  </p>
              </Accordion.Body>
            </Accordion.Item>

            <Accordion.Item eventKey="17">
              <Accordion.Header><h3>15. Do you work with larger bodies?
              </h3></Accordion.Header>
              <Accordion.Body>
                <p>
                  Yes! Absolutely! We are a body safe studio, and ALL sizes are welcome. Contrary to what google
                  images may tell you, you don't need a prescribed "Pilates body" to do Pilates. Want a Pilates
                  body? Do Pilates- in any body, Period.  </p>
              </Accordion.Body>
            </Accordion.Item>

            <Accordion.Item eventKey="18">
              <Accordion.Header><h3>16. Is Pilates only focused on the core?
              </h3></Accordion.Header>
              <Accordion.Body>
                <p>
                  Come and try out a class with us and you will quickly find out that, no, it is not only focused on
                  the core. Is core-work a focus? Yes! Pilates teaches you to use the core to support other
                  movements in the body. Our bodies are more inner connected than we sometimes realize. We
                  strive to provide a full-body workout in every class. </p>
              </Accordion.Body>
            </Accordion.Item>


            <Accordion.Item eventKey="19">
              <Accordion.Header><h3>17. Can men do Pilates?</h3></Accordion.Header>
              <Accordion.Body>
                <p>
                  Yes, men do Pilates too. Pilates was created by a man for men. Pilates increases core strength, improves metabolism and results in a flexible, lean and toned body. It is as good as any other weight-training exercise form, and even better because it combines the principles of mind-body balance along with practices of muscles strengthening, posture correction and improvement of balance.
                </p> </Accordion.Body>
            </Accordion.Item>

            <Accordion.Item eventKey="21">
              <Accordion.Header><h3> 18. Is Pilates safe if I'm pregnant?
              </h3></Accordion.Header>
              <Accordion.Body>
                <p>
                  Yes, Pilates is a wonderful method of exercise during pregnancy. We do require doctors
                  approval before starting, or continuing, classes. Our small group classes are a great way to
                  exercise during pregnancy- you'll be able to get more individual attention and we will modify
                  your exercises as needed.
                </p>    </Accordion.Body>
            </Accordion.Item>


            <Accordion.Item eventKey="23">
              <Accordion.Header><h3>19. Is Pilates good for seniors?</h3></Accordion.Header>
              <Accordion.Body>
                <p>
                  Ofcourse! Pilates can help older individuals improve their mobility, stablish their joints, improve their range of motion, and gain control over their bodies.                   </p>   </Accordion.Body>
            </Accordion.Item>
            <Accordion.Item eventKey="24">
              <Accordion.Header><h3>20. What is your cancellation policy?

              </h3></Accordion.Header>
              <Accordion.Body>
                <p>
                  We understand that emergency events may occur, and you may need to cancel your
                  appointment. Please provide 24 hours’ notice in such cases and we will work to reschedule your
                  session. Our schedule fills out very quickly and your session is reserved especially for you.
                  Failure to show for your session or a cancellation with less than 24 hours’ notice means that
                  your session time is left empty, and another person missed out on the opportunity for a
                  session. We appreciate your respect and understanding for our time. Late cancellations under
                  24 hours will be counted a session used.
                </p>    </Accordion.Body>
            </Accordion.Item>

          </Accordion>

        </div>
      </div>
    </>
  )
}

export default Faqs